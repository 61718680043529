
class ProgrammaticContentFactory {
	constructor(private readonly textarea: HTMLTextAreaElement, private readonly date: HTMLInputElement) {}

	create() {
		return {
      date: this.date.value,
			curriculumComponent: this.textarea.value,
		};
	}

	createPut() {
		return {
			curriculumComponent: this.textarea.value,
      date: this.date.value,
		};
	}
}

export default ProgrammaticContentFactory;
