import React, { useEffect, useState } from 'react';
import './styles.scss';
import { type StudentType } from '../../../class/types/Student/student-factory-protocol';
import { IoIosArrowDown } from 'react-icons/io';
import RoomsApi from '../../../class/Room/RoomsApi';
import { useParams } from 'react-router-dom';
import MathematicDiagnosticAssessmentCostumer from '../../../class/mathematic-diagnostic-assessment/mathematic-diagnostic-assessment-costumer';
import { type MathematicDiagnosticAssessmentObjType } from '../../../class/types/mathematic-diagnostic-assessment';
import MathematicDiagnosticAssessmentApi from '../../../class/mathematic-diagnostic-assessment/mathematic-diagnostic-assessment-api';
import LoadContent from '../../../components/Load-content';
import SaveAlert from '../../../components/save-alert';
import printMathematicsDiagnostic from '../services/print-mathematic-diagnostic';
import MathDiagnosticDashboardContent from '../dashboard-content';
import RuleVerification from '../../../components/rule-verification';

interface MathematicsDiagnosticAssessmentTableProps {
  display: string
  roomInformation: any
}

// {activityInfo.matterId} {activityInfo.twoMonths}

export default function MathematicsDiagnosticAssessmentTable({ display, roomInformation }: MathematicsDiagnosticAssessmentTableProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [students, setStudents] = useState<StudentType[]>([]);
  const [mathDiagnostic, setMathDiagnostic] = useState<MathematicDiagnosticAssessmentObjType>();
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);

  const [displayDashBoard, setDisplayDashboard] = useState<boolean>(false);
  const options = [
    'S',
    'N',
  ];

  const { id, roomName } = useParams();

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!id) {
      return;
    }

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const mathematicsDiagnosticAssessmentCostumer = new MathematicDiagnosticAssessmentCostumer(id, roomInformation.twoMonths, inputs, students);

    setIsLoading(true);
    mathematicsDiagnosticAssessmentCostumer.post().then((response) => {
      setMathDiagnostic(response);
      setIsLoading(false);
      setDisplayAlert(true);
      setTimeout(() => {
        setDisplayAlert(false);
      }, 3000)
    }).catch(e => {

    });
  }

  const huddlePrint = () => {
    if (!mathDiagnostic || !roomName) {
      return;
    }

    printMathematicsDiagnostic(mathDiagnostic, roomInformation.twoMonthsName, roomName);
  }

  const huddleDisplayDashboard = () => {
    setDisplayDashboard(!displayDashBoard);
  }

  useEffect(() => {
    if (display === 'false' || !id) {
      return;
    }

    RoomsApi.getAllStudentByRoom(id).then(students => {
      setStudents(students);
    }).catch(() => {
      //
    });

    if (!roomInformation.twoMonths) {
      return;
    }

    const mathematicDiagnosticAssessmentApi = new MathematicDiagnosticAssessmentApi();

    mathematicDiagnosticAssessmentApi.get(id, roomInformation.twoMonths).then(response => {
      setMathDiagnostic(response);
    }).catch(() => {
      //
    })
  }, [display, roomInformation]);

  return (
    <>
      <SaveAlert display={displayAlert} setDisplay={setDisplayAlert}>Diagnostico de Matemática foi salvo</SaveAlert>
      <MathDiagnosticDashboardContent display={displayDashBoard} setDisplay={setDisplayDashboard} roomInfo={roomInformation} />
      <form className='mathematic-diagnostic-assessment-table-container' method='POST' action='/' onSubmit={huddleSubmit}>
        <div className='mathematic-diagnostic-assessment-table-header'>
          <h1>Diagnostico de Matemática - {roomInformation.twoMonthsName}</h1>
          <div className='buttons-content'>
            <button type="button" onClick={huddleDisplayDashboard}>Gráfico</button>
            <button type='button' onClick={huddlePrint} className='button-print'>imprimir</button>
            <RuleVerification rule={['coordenador']}>
              <>
                {isLoading
                  ? (
                    <LoadContent
                      isLoading={isLoading}
                      style={{
                        button: {
                          width: '30px',
                          height: '30px',
                        },
                        container: {
                          width: '40px'
                        }
                      }}
                    />
                  )
                  : <button type='submit'>Salvar</button>}
              </>
            </RuleVerification>
          </div>
        </div>
        <div className='mathematic-diagnostic-assessment-table-content'>
          <table className='mathematic-diagnostic-assessment-table-element' id='mathematics-diagnostic-assessment-table-element'>
            <thead>
              <tr>
                <th>Nome do Aluno</th>
                <th>Familiares ou Frequentes</th>
                <th>Opacos</th>
                <th>Transparentes</th>
                <th>Terminam com zero</th>
                <th>Generalização</th>
                <th>Zero intercalado</th>
                <th>Composto por algarismos iguais</th>
              </tr>
            </thead>
            <tbody>
              {students.map(student => {
                let mathematicDiagnosticFormatted = ['', '', '', '', '', ''];

                try {
                  if (mathDiagnostic && student.id) {
                    mathematicDiagnosticFormatted = mathDiagnostic[student.id].mathematicDiagnostic
                  }
                } catch (error) {
                  //
                }

                return (
                  <tr key={student.id}>
                    <td id={student.id}>
                      {student.studentName}
                    </td>
                    <td id={student.id}>
                      <InputSelect id={student.id ?? ''} defaultValue={mathematicDiagnosticFormatted[0]} />
                    </td>
                    <td id={student.id}>
                      <InputSelect id={student.id ?? ''} defaultValue={mathematicDiagnosticFormatted[1]} />
                    </td>
                    <td id={student.id}>
                      <InputSelect id={student.id ?? ''} defaultValue={mathematicDiagnosticFormatted[2]} />
                    </td>
                    <td id={student.id}>
                      <InputSelect id={student.id ?? ''} defaultValue={mathematicDiagnosticFormatted[3]} />
                    </td>
                    <td id={student.id}>
                      <InputSelect id={student.id ?? ''} defaultValue={mathematicDiagnosticFormatted[4]} />
                    </td>
                    <td id={student.id}>
                      <InputSelect id={student.id ?? ''} defaultValue={mathematicDiagnosticFormatted[5]} />
                    </td>
                    <td id={student.id}>
                      <InputSelect id={student.id ?? ''} defaultValue={mathematicDiagnosticFormatted[6]} />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </form>
    </>
  )
}

function InputSelect({ id, defaultValue }: { id: string, defaultValue: string | undefined }): React.ReactElement {
  const [display, setDisplay] = useState<boolean>(false);
  const [option, setOption] = useState<string>('')

  const huddleOnClick = (e: React.MouseEvent<HTMLUListElement>) => {
    const element = e.target as HTMLElement;

    if (element.tagName.toUpperCase() === 'LI' && element.textContent) {
      setOption(element.textContent)
      setDisplay(false);
    }
  }

  useEffect(() => {
    setOption(defaultValue ?? '');
  }, [defaultValue]);

  return (
    <div className='mathematic-diagnostic-assessment-input-select'>
      <div className='mathematic-diagnostic-assessment-input-content'>
        <input id={id} type="text" disabled value={option} />
        <IoIosArrowDown className={`icon-arrow display-${String(display)}`} size={20} onClick={() => {
          setDisplay(!display)
        }} />
      </div>
      <ul className={`mathematic-diagnostic-assessment-input-select-options display-${String(display)}`} onClickCapture={huddleOnClick}>
        <li>S</li>
        <li>N</li>
        <li>NR</li>
      </ul>
    </div>
  );
}
