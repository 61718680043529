/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect } from 'react';
import './style.scss';
import ContentTableNotification from '../../../components/notification-files/ContentTable';
import NotificationInformation from '../notification-information';
import BackToTop from '../../../components/back-to-top/back-to-top';
import NotificationFormAdd from '../notification-form-add';
import searchByDate from '../service/searchByDate';
import NotificationFormEdit from '../notification-form-edit';
import type NotificationType from '../../../class/types/notification';
import NotificationAPI from '../../../class/Notification/NotificationAPI';

export default function Table(): React.ReactElement {
  const [notification, setNotification] = useState<NotificationType[]>([]);
  const [visualizationKeys, setVisualizationKeys] = useState<string[]>([]);

  const [notificationsFiltered, setNotificationsFiltered] = useState<any[]>([]);

  const [notificationSelected, setNotificationSelected] = useState<any>({});
  const [displayNotificationInformation, setDisplayNotificationInformation] = useState(false);
  const [displayNotificationFormAdd, setDisplayNotificationFormAdd] = useState<boolean>(false);
  const [displayNotificationFormEdit, setDisplayNotificationFormEdit] = useState<boolean>(false);
  const mainElement = document.getElementById('notification-table') as HTMLDivElement;

  const huddleDisplayFormAdd = () => {
    setDisplayNotificationFormAdd(!displayNotificationFormAdd);
  }

  const huddleFilterSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const notificationsFiltered = searchByDate(notification, inputs.item(0).value, inputs.item(1).value);

    setNotificationsFiltered(notificationsFiltered);
  }

  useEffect(() => {
    setNotificationsFiltered(notification);
  }, [notification]);

  useEffect(() => {
    if (!notificationSelected.visualization) {
      setVisualizationKeys([])
      return;
    }

    const keys = Object.keys(notificationSelected.visualization);

    setVisualizationKeys(keys);
  }, [notificationSelected])

  useEffect(() => {
    if (notification.length > 0) {
      return;
    }

    NotificationAPI.getAll().then(response => {
      setNotification(response);
      setNotificationsFiltered(notification);
    });
  }, [])

  return (
    <div className='notification-table-container'>
      <NotificationInformation
        notification={notificationSelected}
        display={displayNotificationInformation}
        visualizationKeys={visualizationKeys}
        setDisplay={setDisplayNotificationInformation}
      />
      <NotificationFormAdd
        display={displayNotificationFormAdd}
        setDisplay={setDisplayNotificationFormAdd}
        setNotifications={setNotification}
      />
      <NotificationFormEdit
        display={displayNotificationFormEdit}
        setNotifications={setNotification}
        setDisplay={setDisplayNotificationFormEdit}
        notificationSelected={notificationSelected}
      />
      <BackToTop element={mainElement} />
      <header className='header-content'>
        <h1 className='notification-content'>Gerenciamento de Notificações</h1>
        <div className='button-content'>
          <button className='button-submit' onClickCapture={huddleDisplayFormAdd}>Criar Notificação</button>
        </div>
      </header>
      <form method='POST' className='notification-search-content' onSubmitCapture={huddleFilterSubmit}>
        <div className='notification-input-content'>
            <input type="date" />
        </div>
        <h3 className='range-h3-content'>A</h3>
        <div className='notification-input-content'>
            <input type="date" />
        </div>
        <div className='submit-button-content'>
          <button type="submit">Buscar</button>
        </div>
      </form>
      <main className='notification-table-content'>
        <div className='notification-table-content-header'>
          <ul className='notification-table-content-header-list'>
            <li className='notification-table-content-header-li'>
              <h1>Nº</h1>
            </li>
            <li className='notification-table-content-header-li'>
              <h1>Título</h1>
            </li>
            <li className='notification-table-content-header-li'>
              <h1>Emissor</h1>
            </li>
            <li className='notification-table-content-header-li notification-visualization-content'>
              <h1>Visualizações</h1>
            </li>
            <li className='notification-table-content-header-li'>
              <h1>Data</h1>
            </li>
          </ul>
        </div>
        <div className='notification-table' id='notification-table'>
          {notificationsFiltered.map((value, index: number) => {
            return <ContentTableNotification
              setNotificationSelected={setNotificationSelected}
              setDisplayNotificationInformation={setDisplayNotificationInformation}
              setDisplayEdit={setDisplayNotificationFormEdit}
              setNotification={setNotification}
              index={index}
              notification={value}
              key={`2334-${index}`}
            />
          })}
        </div>
      </main>
    </div>
  );
}
