/* eslint-disable @typescript-eslint/consistent-type-assertions */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type UserFactoryProtocol from '../types/User/User-factory-protocol';
import type UserManagerProtocol from '../types/User/User-manager-protocol';
import type { UserClientProtocol } from '../types/User/UserAPI-protocol';
import UserFactory from './User-factory';
import UserAPI from './UserAPI';

class UserManager implements UserManagerProtocol {
	private readonly userFactory: UserFactoryProtocol;
	private readonly userApi = UserAPI;

	constructor(private readonly inputs: NodeListOf<HTMLInputElement>) {
		this.userFactory = new UserFactory(this.inputs);
	}

	async register(): Promise<any> {
		let user = this.userFactory.create();
    console.log(user);
		await UserAPI.post(user).then((userServer: any) => {
			user = userServer;
		});
		return user;
	}

	async put(id: string): Promise<UserClientProtocol> {
		let user = {} as UserClientProtocol;

		const userFactory = new UserFactory(this.inputs);

		const userPut = userFactory.createPut();

		await this.userApi.put(userPut, id).then((response: any) => {
			user = response as UserClientProtocol;
		});

		return user;
	}

	async login(): Promise<any> {
		let user = this.userFactory.createLogin();

		if (!user.email) {
			return;
		}

		await this.userApi.get(user.email, user.password).then((userServer: any) => {
			user = userServer;
		});
		return user;
	}
}

export default UserManager;
