/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-negated-condition */

import React from 'react';
import './style.scss';

interface FrequencyInputElementProps {
  frequency: {
    studentId: string
    studentName: string
    present: string
  }
  index: number
}

export default function FrequencyInformationElement({ frequency, index }: FrequencyInputElementProps): React.ReactElement {
  if (!frequency.present) {
    return <></>;
  }

  const returnPorcentual = (x: number, y: number) => {
    let menor = 0;
    let maior = 0;

    if (x > y) {
      maior = x;
      menor = y;
    } else {
      maior = y;
      menor = x;
    }

    return (menor / (x + y)) * 100;
  }

  return (
		<ul className='student-frequency-information-content-body-list'>
			<li>
				{index + 1}
			</li>
			<li>
				{frequency.studentName}
			</li>
			<li className={`present-element frequency-${frequency.present}`}>
				{frequency.present === 'true' ? 'C' : 'F'}
			</li>
		</ul>
  );
}
