/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import studentWritingLevelRecordApi from '../../../class/student-writing-level-record/student-writing-level-record-api';
import './style.scss';
import { IoIosArrowDown } from 'react-icons/io';
import Dashboard from './components/dashboard';
import ToCompareDashboard from './components/to-compare-dashboard';
import { type DataWritingLevel, type TagTitleList } from '../../../class/types/Student-writing-level-record/student-writing-level-record-factory-protocol';
import generatePDF from './service/generetePDF';

interface DashboardWritingLevelProps {
  display: string
}

interface HuddleClickLiCompareType {
  isLoading: boolean
  setOptionTag: React.Dispatch<React.SetStateAction<string>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setOption: React.Dispatch<React.SetStateAction<string>>
  setOptionsTag: React.Dispatch<React.SetStateAction<TagTitleList[]>>
  setDisplayOption: React.Dispatch<React.SetStateAction<boolean>>
  setDataDashboard: React.Dispatch<React.SetStateAction<DashboardDataType>>
  setTwoMonthsSelected: React.Dispatch<React.SetStateAction<string>>
  setTypeSelected: React.Dispatch<React.SetStateAction<string>>
}
interface HuddleClickLiTagType {
  twoMonthsSelected: string
  typeSelected: string
  optionsTag: TagTitleList[]
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setOption: React.Dispatch<React.SetStateAction<string>>
  setDisplayOption: React.Dispatch<React.SetStateAction<boolean>>
  setDataDashboard: React.Dispatch<React.SetStateAction<DashboardDataType>>
}

interface DashboardDataType {
  series: number[]
  colors: string[]
  categories: string[]
}

const defaultDataDashboard: DashboardDataType = {
  colors: ['#ff0000'],
  categories: ['dados não encontrado'],
  series: [100]
};

const defaultTwoMonths = [
  'twoMonths-one',
  'twoMonths-tree'
];

export default function DashboardWritingLevel({ display }: DashboardWritingLevelProps): React.ReactElement {
  const [option, setOption] = useState<string>('Bimestres');
  const [optionTwo, setOptionTwo] = useState<string>('Bimestres');
  const [optionTree, setOptionTree] = useState<string>('Bimestres');

  const [optionTag, setOptionTag] = useState<string>('Tags');
  const [optionTagTwo, setOptionTagTwo] = useState<string>('Tags');
  const [optionTagThree, setOptionTagThree] = useState<string>('Tags');

  const [optionsTag, setOptionsTag] = useState<TagTitleList[]>([]);
  const [optionsTagTwo, setOptionsTagTwo] = useState<TagTitleList[]>([]);
  const [optionsTagThree, setOptionsTagThree] = useState<TagTitleList[]>([]);

  const [displayOption, setDisplayOption] = useState<boolean>(false);
  const [displayOptionTwo, setDisplayOptionTwo] = useState<boolean>(false);
  const [displayOptionTree, setDisplayOptionTree] = useState<boolean>(false);

  const [displayOptionTag, setDisplayOptionTag] = useState<boolean>(false);
  const [displayOptionTagTwo, setDisplayOptionTagTwo] = useState<boolean>(false);
  const [displayOptionTagThree, setDisplayOptionTagThree] = useState<boolean>(false);

  const [dataDashboardOne, setDataDashboardOne] = useState<DashboardDataType>(defaultDataDashboard);
  const [dataDashboardTwo, setDataDashboardTwo] = useState<DashboardDataType>(defaultDataDashboard);
  const [dataDashboardTree, setDataDashboardTree] = useState<DashboardDataType>(defaultDataDashboard);

  const [displayToCompareDashboard, setDisplayToCompareDashboard] = useState<boolean>(false);

  const [twoMonthsSelected, setTwoMonthsSelected] = useState<string>('');
  const [twoMonthsSelectedTwo, setTwoMonthsSelectedTwo] = useState<string>('');
  const [twoMonthsSelectedThree, setTwoMonthsSelectedThree] = useState<string>('');

  const [typeSelected, setTypeSelected] = useState<string>('');
  const [typeSelectedTwo, setTypeSelectedTwo] = useState<string>('');
  const [typeSelectedThree, setTypeSelectedThree] = useState<string>('');

  const [nrLength, setNRLength] = useState<number>(0);

  const [isLoadingOne, setIsLoadingOne] = useState(false);
  const [isLoadingTwo, setIsLoadingTwo] = useState(false);
  const [isLoadingThree, setIsLoadingThree] = useState(false);

  const { id, roomName } = useParams();

  const huddleDisplayOption = (get: boolean, set: React.Dispatch<React.SetStateAction<boolean>>) => {
    set(!get);
  };

  const huddleDisplayToCompareDashboard = () => {
    setDisplayToCompareDashboard(!displayToCompareDashboard);
  };

  const huddleClickLi = (e: React.MouseEvent<HTMLUListElement>, {
    setOptionTag,
    setIsLoading,
    setTwoMonthsSelected,
    setTypeSelected,
    setOption,
    setOptionsTag,
    setDisplayOption,
    setDataDashboard
  }: HuddleClickLiCompareType) => {
    const target = e.target as HTMLLIElement;
    const elementId = target.id;

    setOption(!target.textContent ? '' : target.textContent);
    setDisplayOption(false);

    if (!id) {
      return;
    }

    const [tagType, twoMonths] = elementId.split(' ');

    if (tagType.includes('school-year')) {
      dataWritingLevelFinalResultCostumer(elementId, id, setDataDashboard, setIsLoading);
      return;
    }

    let semesterTwoMonths = ''

    setOptionTag('Tags');
    if (tagType.includes('semester')) {
      semesterTwoMonths = defaultTwoMonths[Number(twoMonths) - 1];
    }

    setDataDashboard(defaultDataDashboard);
    studentWritingLevelRecordApi.getAllTagTitleList(id, tagType.includes('semester') ? semesterTwoMonths : twoMonths).then(response => {
      setTwoMonthsSelected(twoMonths);
      setOptionsTag(response);
      setTypeSelected(tagType);
    });
  };

  const huddleClickListTag = (e: React.MouseEvent<HTMLUListElement>, {
    twoMonthsSelected,
    typeSelected,
    optionsTag,
    setIsLoading,
    setDisplayOption,
    setOption,
    setDataDashboard
  }: HuddleClickLiTagType) => {
    const target = e.target as HTMLLIElement;
    const content = target.textContent;

    setOption(!content ? '' : content);
    setDisplayOption(false);

    if (!id || !content) {
      return;
    }

    let _index = 0;
    const tagFiltered = optionsTag.find((tag, index) => {
      if (tag.name.includes(content)) {
        _index = index + 1;
        return true;
      }
      return false;
    });

    if (typeSelected.includes('semester')) {
      if (content.includes('Nível de Escrita')) {
        dataWritingLevelFinalResultCostumer(`${typeSelected} ${twoMonthsSelected}`, id, setDataDashboard, setIsLoading);
        return;
      }
      setIsLoading(true);
      studentWritingLevelRecordApi.getDataTagTitleBySemester(id, Number(twoMonthsSelected), tagFiltered?.id ?? '').then(response => {
        setIsLoading(false);
        const responseFormat = removeNR(response);

        setDataDashboard({
          colors: responseFormat.color,
          categories: responseFormat.category,
          series: responseFormat.series
        });
      });
      return;
    }

    if (!tagFiltered) {
      dataWritingLevelFinalResultCostumer(`${typeSelected} ${twoMonthsSelected}`, id, setDataDashboard, setIsLoading);
      return;
    }

    setIsLoading(true);
    studentWritingLevelRecordApi.getDateSpecificTagTitle(id, twoMonthsSelected, tagFiltered?.id ?? '', _index).then((response: any) => {
      setIsLoading(false);

     const responseFormat = removeNR(response);

      setDataDashboard({
        colors: responseFormat.color,
        categories: responseFormat.category,
        series: responseFormat.series
      });
    });
  }

  const dataWritingLevelFinalResultCostumer = (
    id: string,
    roomId: string,
    setDataDashboard: React.Dispatch<React.SetStateAction<DashboardDataType>>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setIsLoading(true);
    studentWritingLevelRecordApi.getDataWritingLevelFinalResultCostumer(id, roomId).then(response => {
      setIsLoading(false);
      if (!response) {
        return;
      }

      const responseFormat = removeNR(response);

      setDataDashboard({
        colors: responseFormat.color,
        categories: responseFormat.category,
        series: responseFormat.series
      });
    });
  };

  const huddlePrint = () => {
    if (!roomName) {
      return;
    }
    generatePDF(
      roomName,
      optionTwo,
      optionTree,
      optionTagTwo,
      optionTagThree,
    );
  }

  const removeNR = (response: DataWritingLevel): DataWritingLevel => {
    let NRindex = 0;
    let NRSearched = false;
    if (!response) {
      return {
        category: [],
        color: [],
        series: [],
        seriesCount: [],
        writingTheName: 0,
      };
    }
    response.category.map((value: any, index: number) => {
      if (NRSearched) {
        return value;
      }
      if (value === 'NR') {
        NRindex = index;
        NRSearched = true;
      }
      return value;
    });

    setNRLength(!response.seriesCount ? 0 : response.seriesCount[NRindex]);

    response.category.splice(NRindex, 1);
    response.series.splice(NRindex, 1);

    return response;
  }

  useEffect(() => {
    if (display === 'false' || !id) {
      return;
    }

    studentWritingLevelRecordApi.getDataWritingLevelFinalResult(id).then(response => {
      const responseFormat = removeNR(response);

      setDataDashboardOne({
        colors: responseFormat.color,
        categories: responseFormat.category,
        series: responseFormat.series
      });
    });
  }, [display]);

  if (!display) {
    return <></>;
  }

  return (
    <div className={'dashboard-writing-level-final-result display-true'}>
      <header className='header-content'>
        <div className='filter-content'>
          <h1 className={`display-${!displayToCompareDashboard}`}>Filtros:</h1>
          <div className={`input-content display-${!displayToCompareDashboard}`}>
            <div className='input-container-element' onClickCapture={() => {
              huddleDisplayOption(displayOption, setDisplayOption);
            }}>
              <input type='text' value={option} disabled={true} />
              <IoIosArrowDown className={`icon-arrow display-${displayOption}`} size={20} />
            </div>
            <ul className={`list-option-content display-${displayOption}`} onClickCapture={e => {
              huddleClickLi(e, {
                isLoading: isLoadingOne,
                setOptionTag,
                setIsLoading: setIsLoadingOne,
                setTwoMonthsSelected,
                setTypeSelected,
                setOption,
                setOptionsTag,
                setDisplayOption,
                setDataDashboard: setDataDashboardOne
              });
            }}>
              {/* <li id='school-year'>Ano Letivo</li> */}
              <li id='twoMonths twoMonths-one'>1º Bimestre</li>
              <li id='twoMonths twoMonths-two'>2º Bimestre</li>
              <li id='twoMonths twoMonths-tree'>3º Bimestre</li>
              <li id='twoMonths twoMonths-for'>4º Bimestre</li>
              <li id='semester 1'>1º Semestre</li>
              <li id='semester 2'>2º Semestre</li>
            </ul>
          </div>
          <div className={`input-content display-${!displayToCompareDashboard}`}>
            <div className='input-container-element' onClickCapture={() => {
              huddleDisplayOption(displayOptionTag, setDisplayOptionTag);
            }}>
              <input type='text' value={optionTag} disabled={true} />
              <IoIosArrowDown className={`icon-arrow display-${displayOptionTag}`} size={20} />
            </div>
            <ul className={`list-option-content display-${displayOptionTag}`} onClickCapture={e => {
              huddleClickListTag(e, {
                typeSelected,
                twoMonthsSelected,
                optionsTag,
                isLoading: isLoadingOne,
                setIsLoading: setIsLoadingOne,
                setOption: setOptionTag,
                setDisplayOption: setDisplayOptionTag,
                setDataDashboard: setDataDashboardOne
              });
            }}>
              <li id='school-year'>Nível de Escrita</li>
              {optionsTag.map(tag => {
                return <li key={tag.id}>{tag.name}</li>
              })}
            </ul>
          </div>
          <div className='to-compare-button-content'>
            <button type='button' onClick={huddleDisplayToCompareDashboard}>
              {displayToCompareDashboard ? 'Mostrar separadamente' : 'Comparar'}
            </button>
          </div>
          {displayToCompareDashboard
            ? (
              <div className='to-compare-button-content'>
                <button type='button' onClick={huddlePrint}>Gerar PDF</button>
              </div>
            )
            : ''}
          <div className={`to-compare-input-container  display-${displayToCompareDashboard}`}>
            <h1>Gráfico um:</h1>
            <div className='input-content'>
              <div className='input-container-element' onClickCapture={() => {
                huddleDisplayOption(displayOptionTwo, setDisplayOptionTwo);
              }}>
                <input type='text' value={optionTwo} disabled={true} />
                <IoIosArrowDown className={`icon-arrow display-${displayOptionTwo}`} size={20} />
              </div>
              <ul className={`list-option-content display-${displayOptionTwo}`} onClickCapture={e => {
                huddleClickLi(e, {
                  isLoading: isLoadingTwo,
                  setOptionTag: setOptionTagTwo,
                  setIsLoading: setIsLoadingTwo,
                  setTwoMonthsSelected: setTwoMonthsSelectedTwo,
                  setTypeSelected: setTypeSelectedTwo,
                  setOption: setOptionTwo,
                  setOptionsTag: setOptionsTagTwo,
                  setDisplayOption: setDisplayOptionTwo,
                  setDataDashboard: setDataDashboardTwo
                });
              }}>
                <li id='twoMonths twoMonths-one'>1º Bimestre</li>
                <li id='twoMonths twoMonths-two'>2º Bimestre</li>
                <li id='twoMonths twoMonths-tree'>3º Bimestre</li>
                <li id='twoMonths twoMonths-for'>4º Bimestre</li>
                <li id='semester 1'>1º Semestre</li>
                <li id='semester 2'>2º Semestre</li>
              </ul>
            </div>
            <div className='input-content'>
              <div className='input-container-element' onClickCapture={() => {
                huddleDisplayOption(displayOptionTagTwo, setDisplayOptionTagTwo);
              }}>
                <input type='text' value={optionTagTwo} disabled={true} />
                <IoIosArrowDown className={`icon-arrow display-${displayOptionTagTwo}`} size={20} />
              </div>
              <ul className={`list-option-content display-${displayOptionTagTwo}`} onClickCapture={e => {
                huddleClickListTag(e, {
                  twoMonthsSelected: twoMonthsSelectedTwo,
                  typeSelected: typeSelectedTwo,
                  optionsTag: optionsTagTwo,
                  isLoading: isLoadingTwo,
                  setIsLoading: setIsLoadingTwo,
                  setOption: setOptionTagTwo,
                  setDisplayOption: setDisplayOptionTagTwo,
                  setDataDashboard: setDataDashboardTwo
                });
              }}>
                <li>Nível de Escrita</li>
                {optionsTagTwo.map(tag => {
                  return <li key={tag.id}>{tag.name}</li>
                })}
              </ul>
            </div>
          </div>
          <div className={`to-compare-input-container  display-${displayToCompareDashboard}`}>
            <h1>Gráfico dois:</h1>
            <div className='input-content'>
              <div className='input-container-element' onClickCapture={() => {
                huddleDisplayOption(displayOptionTree, setDisplayOptionTree);
              }}>
                <input type='text' value={optionTree} disabled={true} />
                <IoIosArrowDown className={`icon-arrow display-${displayOptionTree}`} size={20} />
              </div>
              <ul className={`list-option-content display-${displayOptionTree}`} onClickCapture={e => {
                huddleClickLi(e, {
                  isLoading: isLoadingThree,
                  setOptionTag: setOptionTagThree,
                  setIsLoading: setIsLoadingThree,
                  setTwoMonthsSelected: setTwoMonthsSelectedThree,
                  setTypeSelected: setTypeSelectedThree,
                  setOption: setOptionTree,
                  setOptionsTag: setOptionsTagThree,
                  setDisplayOption: setDisplayOptionTree,
                  setDataDashboard: setDataDashboardTree
                });
              }}>
                <li id='twoMonths twoMonths-one'>1º Bimestre</li>
                <li id='twoMonths twoMonths-two'>2º Bimestre</li>
                <li id='twoMonths twoMonths-tree'>3º Bimestre</li>
                <li id='twoMonths twoMonths-for'>4º Bimestre</li>
                <li id='semester 1'>1º Semestre</li>
                <li id='semester 2'>2º Semestre</li>
              </ul>
            </div>
            <div className='input-content'>
              <div className='input-container-element' onClickCapture={() => {
                huddleDisplayOption(displayOptionTagThree, setDisplayOptionTagThree);
              }}>
                <input type='text' value={optionTagThree} disabled={true} />
                <IoIosArrowDown className={`icon-arrow display-${displayOptionTagThree}`} size={20} />
              </div>
              <ul className={`list-option-content display-${displayOptionTagThree}`} onClickCapture={e => {
                huddleClickListTag(e, {
                  twoMonthsSelected: twoMonthsSelectedThree,
                  typeSelected: typeSelectedThree,
                  optionsTag: optionsTagThree,
                  isLoading: isLoadingThree,
                  setIsLoading: setIsLoadingThree,
                  setOption: setOptionTagThree,
                  setDisplayOption: setDisplayOptionTagThree,
                  setDataDashboard: setDataDashboardTree
                });
              }}>
                <li>Nível de Escrita</li>
                {optionsTagThree.map(tag => {
                  return <li key={tag.id}>{tag.name}</li>
                })}
              </ul>
            </div>
          </div>
        </div>
      </header>
      {displayToCompareDashboard
        ? (
          <ToCompareDashboard
            display={displayToCompareDashboard}
            dataDashboardTwo={dataDashboardTwo}
            dataDashboardTree={dataDashboardTree}
            isLoadingThree={isLoadingThree}
            isLoadingTwo={isLoadingTwo}
            optionTree={optionTree}
            optionTwo={optionTwo}
            roomName={`${roomName}`}
          />
        )
        : ''}
      {!displayToCompareDashboard
        ? (
          <Dashboard
            display={!displayToCompareDashboard}
            categories={dataDashboardOne.categories}
            colors={dataDashboardOne.colors}
            option={option}
            roomName={roomName ?? ''}
            series={dataDashboardOne.series}
            isLoading={isLoadingOne}
          />
        )
        : ''}
    </div>
  );
}
