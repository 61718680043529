/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import lscache from 'lscache';
import React, { useEffect, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import activityApi from '../../../class/ProgrammaticContent/programmaticContentApi';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import './style.scss';
import LoadContent from '../../Load-content';
import type ProgrammaticContentResProtocol from '../../../class/types/ProgrammaticContent/ProgrammaticContentApi-protocol';
import RuleVerification from '../../rule-verification';
import { HiCheck } from 'react-icons/hi';
import { BsFillCheckCircleFill } from 'react-icons/bs';

interface ContentTableType {
  programmaticContent: ProgrammaticContentResProtocol
  activityInfo: Record<string, string>
  index: number
  setElementId: React.Dispatch<React.SetStateAction<string>>
  editElementDisplay: boolean
  setDisplayReadChecking: React.Dispatch<React.SetStateAction<boolean>>
  setEditElementDisplay: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayProgrammaticContentInformation: React.Dispatch<React.SetStateAction<boolean>>
  setProgrammaticContentCostumer: React.Dispatch<React.SetStateAction<ProgrammaticContentResProtocol[]>>
}

// {activityInfo.matterId} {activityInfo.twoMonths}

export default function ContentTableProgrammaticContent({ programmaticContent, index, editElementDisplay, activityInfo, setProgrammaticContentCostumer, setDisplayReadChecking, setElementId, setEditElementDisplay, setDisplayProgrammaticContentInformation }: ContentTableType): React.ReactElement {
  const [user, setUser] = useState<UserClientProtocol>();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
   const [displayAnimation, setDisplayAnimation] = useState(false);

  const huddleDelete = () => {
    if (id && programmaticContent.id) {
      setIsLoading(true);
      activityApi.delete(id, activityInfo.matterId, activityInfo.twoMonths, programmaticContent.id).then(response => {
        setProgrammaticContentCostumer(response);
        setIsLoading(false);
      }).catch(e => {
        //
      });
    }
  };

  const huddleClick = (event: any) => {
    const target = event.target as HTMLDivElement;

    if (target.tagName === 'path' || target.tagName === 'svg') {
      return;
    }

    setElementId(programmaticContent.id ?? '');
    setDisplayProgrammaticContentInformation(true);
  }

  const hundleEdit = () => {
    setEditElementDisplay(!editElementDisplay);
    if (!programmaticContent.id) {
      return;
    }

    setElementId(programmaticContent.id);
  };

  const convertDate = (date: string) => {
    if (!date.includes('-')) {
      return date;
    }

    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC'
    });
  }

  useEffect(() => {
    const user = lscache.get('user');
    if (user) {
      setUser(user);
    }
  }, []);

  const huddleDisplayReadChecking = () => {
    if (!programmaticContent.id) {
      return;
    }

    setDisplayReadChecking(true);
    setElementId(programmaticContent.id);
  }

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [programmaticContent]);
  console.log(programmaticContent)
  return (
		<div className={`content-table-element-programmatic-content display-animation-${displayAnimation}`}>
			<ul className='content-table-ul-roms' onClick={huddleClick}>
				<li className='content-table-ul-li'>
					<h2>{index + 1}</h2>
				</li>
				<li className='content-table-ul-li'>
					<h2>
						{convertDate(programmaticContent.date ?? '')}
					</h2>
				</li>
        <li className='content-table-ul-li status-icon-element'>
          {programmaticContent.checked
            ? (
              <BsFillCheckCircleFill className='icon-element' style={{ color: '#118951' }} size={21} />
            )
            : ''
          }
        </li>
				<li className='header-table-ul-li' onClick={hundleEdit}>
					<FiEdit2 size='20' style={{ color: '#118951' }} />
				</li>
        <RuleVerification rule={['coordenador']}>
          <li className='content-table-ul-li'>
            <HiCheck size='20' onClickCapture={huddleDisplayReadChecking} className='icon-hicheck-element' />
          </li>
        </RuleVerification>
				{isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>
          )}
			</ul>
		</div>
  );
}
