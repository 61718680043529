/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable react/jsx-key */

import React, { useEffect, useState } from 'react';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import ContentTableRoom from '../../../components/room-files/ContentTable-room';
import FormAddElement from '../FormAddElement-room';
import EmptyComponent from '../../../components/Empty-component';
import SearchElement from '../../../components/Search-element';
import { useParams } from 'react-router-dom';
import './style.scss';
import StudentAPI from '../../../class/Student/StudentAPI';
import StudentInfo from '../info-student';
import RoomsApi from '../../../class/Room/RoomsApi';
import lscache from 'lscache';
import RuleVerification from '../../../components/rule-verification';
import printStudentByRoom from '../service/print-student-by-room';
import FormUpdateStudentSituationOfRoom from '../form-student-situation';
import { PiStudentFill } from 'react-icons/pi';
import { BsPeopleFill } from 'react-icons/bs';
import LoadContent from '../../../components/Load-content';

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState(false);
  const [studentRoom, setStudentRoom] = useState<StudentType[]>([]);
  const [studentFiltered, setStudentFiltered] = useState<any[]>([]);
  const [elementId, setElementId] = useState<string>('');
  const [displayStudentInfo, setDisplayStudentInfo] = useState<boolean>(false);
  const [displayStudentSituationOfRoom, setDisplayStudentSituationOfRoom] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id, roomName } = useParams();

  const hundleCreateNewElement = () => {
    setDisplayCreateNewElement(!displayCreateNewElement);
  };

  const print = () => {
    if (!roomName) {
      return;
    }
    printStudentByRoom(studentRoom, roomName);
  }

  useEffect(() => {
    if (display === 'false' || studentRoom.length > 0 || isLoading) {
      return;
    }

    if (!id) {
      return;
    }

    setIsLoading(true);
    RoomsApi.getAllStudentByRoom(id).then(response => {
      setStudentRoom(response);
      setStudentFiltered(response);
      setIsLoading(false);
    });
  }, [display]);

  return (
    <div className='table-container-room'>
      <FormAddElement
        setDisplayCreateNewElement={setDisplayCreateNewElement}
        displayCreateNewElement={displayCreateNewElement}
        setStudentRoom={setStudentFiltered}
      />
      <FormUpdateStudentSituationOfRoom
        displayStudentSituationOfRoom={displayStudentSituationOfRoom}
        setDisplayStudentSituationOfRoom={setDisplayStudentSituationOfRoom}
        setStudentRoom={setStudentFiltered}
        studentId={elementId}
      />
      <div className='btn-create-new-room-container'>
        <RuleVerification rule={['coordenador', 'professor']}>
          <button type='button' onClick={hundleCreateNewElement}>Adicionar aluno</button>
        </RuleVerification>
        <button type='button' onClick={print}>Imprimir</button>
      </div>
      <StudentInfo
        displayStudentInfo={displayStudentInfo}
        students={studentRoom}
        setDisplayStudentInfo={setDisplayStudentInfo}
        elementId={elementId}
      />
      <div className='student-rom-search-content'>
        <SearchElement
          elements={studentRoom}
          setElementFiltered={setStudentFiltered}
          elementFiltered={studentFiltered}
          elementKey={'studentName'}
        />
        <div className='student-qtd'>
          <span>{studentRoom.length}</span>
          <BsPeopleFill className='student-qtd-icon' />
        </div>
      </div>
      <div className='header-table-element-action' >
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Nome</li>
          <li className='header-table-ul-li'>Responsável</li>
          <li className='header-table-ul-li'>RA</li>
          <li className='header-table-ul-li'>UF</li>
          <li className='header-table-ul-li'>Bairro</li>
          <li className='header-table-ul-li'>Situação</li>
          <li className='header-table-ul-li'></li>
        </ul>
      </div>
      <main className='main-table-action'>
        {isLoading ? <LoadContent isLoading={isLoading} /> : (studentFiltered.length === 0
              ? <EmptyComponent>click em (Adicionar aluno) para adicionar um aluno</EmptyComponent>
              : studentFiltered.map((student, index) => (
                <ContentTableRoom
                  student={student}
                  index={index}
                  setDisplayStudentInfo={setDisplayStudentInfo}
                  setStudentRoom={setStudentFiltered}
                  setElementId={setElementId}
                  setDisplaySituationOfRoom={setDisplayStudentSituationOfRoom}
                  displaySituationOfRoom={displayStudentSituationOfRoom}
                />
              ))
          )}
      </main>
    </div>
  );
}
