/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import LoadingContent from '../../../../../components/Load-content';
import Chart from 'react-apexcharts';
import './style.scss';
import compareDataDashboard from './services/compare-dataDashboard';

interface DataDashboardType {
  series: number[]
  categories: string[]
  colors: string[]
}

interface ToCompareDashboardType {
  display: boolean
  dataDashboardTwo: DataDashboardType
  dataDashboardTree: DataDashboardType
  isLoadingTwo: boolean
  isLoadingThree: boolean
  roomName: string
  optionTwo: string
  optionTree: string
}

export default function ToCompareDashboard({
  display,
  isLoadingThree,
  isLoadingTwo,
  dataDashboardTwo,
  dataDashboardTree,
  optionTwo,
  optionTree,
  roomName
}: ToCompareDashboardType): React.ReactElement {
  const [newSeries, setNewSeries] = useState<number[]>([]);

  useEffect(() => {
    const newSeries = compareDataDashboard(dataDashboardTwo.series, dataDashboardTree.series);

    setNewSeries(newSeries);
  }, [dataDashboardTree, dataDashboardTree]);

  if (!display) {
    return <></>;
  }

  return (
    <div className={`to-compare-dashboard-component display-${display}`} id='print-graphic-content-writing-hipotese-final-result'>
      <div className='dashboard-content'>
        {!isLoadingTwo
          ? (
            <Chart
              className='graphic-content'
              type='pie'
              width={450}
              height={450}
              series={dataDashboardTwo.series}
              options={{
                labels: dataDashboardTwo.categories,
                colors: dataDashboardTwo.colors,
                legend: {
                  fontSize: '16rem',
                  offsetY: 70,
                  show: false
                },
                stroke: {
                  show: true,
                  curve: 'smooth',
                  lineCap: 'butt',
                  colors: ['#000000'],
                  width: 0.5,
                  dashArray: 0
                },
                chart: {
                  width: '50px',
                  height: '50px'
                },
                plotOptions: {
                  pie: {
                    donut: {
                      background: '#000000'
                    }
                  }
                },
                title: {
                  align: 'center',
                  margin: 30,
                  style: {
                    fontSize: '1.2rem'
                  },
                  text: `Hipótese de Escrita | ${optionTwo} ${roomName}`
                }
              }}
            />
          )
          : <LoadingContent
            isLoading={isLoadingTwo}
          />}
        {!isLoadingThree
          ? (
            <Chart
              className='graphic-content'
              type='pie'
              width={450}
              height={450}
              series={dataDashboardTree.series}
              options={{
                labels: dataDashboardTree.categories,
                colors: dataDashboardTree.colors,
                legend: {
                  fontSize: '2rem',
                  offsetY: 70,
                  show: false
                },
                stroke: {
                  show: true,
                  curve: 'smooth',
                  lineCap: 'butt',
                  colors: ['#000000'],
                  width: 0.5,
                  dashArray: 0
                },
                chart: {
                  width: '50px',
                  height: '50px'
                },
                title: {
                  align: 'center',
                  margin: 30,
                  style: {
                    fontSize: '1.2rem'
                  },
                  text: `Hipótese de Escrita | ${optionTree} ${roomName}`
                }
              }}
            />
          )
          : <LoadingContent
            isLoading={isLoadingThree}
          />}
      </div>
      <div className='breakthrough-dashboard'>
        {(!isLoadingTwo && !isLoadingThree) ? (
          <Chart
            className='graphic-content'
            type='bar'
            width={700}
            options={{
              chart: {
                width: 800,
                type: 'bar'
              },
              stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: ['#000000'],
                width: 1,
                dashArray: 0
              },
              title: {
                align: 'center',
                margin: 30,
                style: {
                  fontSize: '1.2rem'
                },
                text: `Hipótese de Escrita | ${optionTwo} - ${optionTree} - ${roomName}`
              },
              colors: dataDashboardTree.colors,
              dataLabels: {
                enabled: true,
                formatter(val) {
                  return `${val}%`;
                },
                offsetY: 10,
                style: {
                  fontSize: '12px',
                  colors: ['#304758']
                }
              },
              legend: {
                show: false
              },
              plotOptions: {
                bar: {
                  columnWidth: '45%',
                  distributed: true,
                  dataLabels: {
                    position: 'top' // Top, center, bottom
                  }
                }
              },
              xaxis: {
                categories: dataDashboardTree.categories,
                labels: {
                  style: {
                    colors: '#000000',
                    fontWeight: '600',
                    fontSize: '10px'
                  }
                }
              }
            }}
            series={[
              {
                data: newSeries
              }
            ]}
          />
        ) : <LoadingContent isLoading={true} />}
      </div>
    </div>
  );
}
