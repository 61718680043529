/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import InputSelect from '../../../components/input-select';
import Chart from 'react-apexcharts';
import MathematicDiagnosticAssessmentApi from '../../../class/mathematic-diagnostic-assessment/mathematic-diagnostic-assessment-api';
import { useParams } from 'react-router-dom';
import MathematicsDiagnosticAssessmentGeneralDashboardComparative from '../mathematics-problem-solving-general-dashboard-comparative';
import MathematicsProblemSolvingApi from '../../../class/Mathematics-problem-solving/MathematicsProblemSolving-api';
import type MathematicsProblemSolvingTagType from '../../../class/types/MathematicsProblemSolving';

const defaultBgImg = require('./img/undraw_Pie_chart_re_bgs8.png');

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [mathematicDiagnosticDate, setMathematicDiagnosticDate] = useState<
    {
      right: number[]
      wrong: number[]
    }>({
      right: [],
      wrong: []
    });
  const [optionSelected, setOptionSelected] = useState<number[]>([]);
  const [textOfOption, setTextOfOption] = useState<string>('');
  const [textOfOptionTwoMonths, setTextOfOptionTwoMonths] = useState<string>('');

  const [displayComparative, setDisplayComparative] = useState<boolean>(false);

  const [deleteOption, setDeleteOption] = useState<boolean>(false);

  const { id } = useParams();

  const twoMonths = [
    'twoMonths-one',
    'twoMonths-two',
    'twoMonths-tree',
    'twoMonths-for',
  ]

  const twoMonthsOptions = [
    '1º Bimestre',
    '2º Bimestre',
    '3º Bimestre',
    '4º Bimestre',
  ]
  const [options, setOptions] = useState<MathematicsProblemSolvingTagType[]>([]);

  const huddleInputSelectTwoMonths = (option: string) => {
    if (!id) {
      return;
    }

    setTextOfOptionTwoMonths(option);

    setDeleteOption(!deleteOption);
    setOptionSelected([0, 0]);

    const mathematicProblemSolvingApi = new MathematicsProblemSolvingApi();
    mathematicProblemSolvingApi.getDateByTwoMonths(id, twoMonths[twoMonthsOptions.indexOf(option)]).then(response => {
      setMathematicDiagnosticDate(response);
    }).catch(e => {

    });
  }

  const huddleInputSelectNivel = (option: string) => {
    const indexTag = Number(option.split(' ')[0]) - 1;

    if (mathematicDiagnosticDate.right.length === 0 && mathematicDiagnosticDate.wrong.length === 0) {
      setOptionSelected([0, 0]);
      setTextOfOption('');
      return;
    }

    setOptionSelected([
      mathematicDiagnosticDate.right[indexTag],
      mathematicDiagnosticDate.wrong[indexTag]
    ]);
    setTextOfOption(option);
  }

  const huddleDisplayComparative = () => {
    setDisplayComparative(true);
  }

  useEffect(() => {
    if (display === 'false' || !id) {
      return;
    }
    const mathematicsProblemSolvingApi = new MathematicsProblemSolvingApi();

    mathematicsProblemSolvingApi.getTags(id).then(response => {
      setOptions(response);
    }).catch(() => {
      //
    });
  }, [display]);

  return (
    <>
      <MathematicsDiagnosticAssessmentGeneralDashboardComparative display={displayComparative} setDisplay={setDisplayComparative} />
      <div className='mathematics-problem-solving-general-table'>
        <div className='mathematics-problem-solving-general-table-header'>
          <h1>Filtros</h1>
          <InputSelect
            options={twoMonthsOptions}
            defaultValue='Bimestre'
            cb={huddleInputSelectTwoMonths}
            title=''
          />
          <InputSelect
            options={options.map((option, index) => `${index + 1} ${option.content}`)}
            defaultValue='Níveis'
            cb={huddleInputSelectNivel}
            deleteAll={deleteOption}
            title=''
          />
          <button type='button' className='button-content' onClick={huddleDisplayComparative}>Comparar</button>
        </div>
        <div className='problem-solving-dashboard-content'>
          {
            optionSelected.length > 0
              ? (
                <>
                  <Chart
                    type='pie'
                    width={700}
                    height={400}
                    series={optionSelected}
                    options={{
                      labels: ['Acertou', 'Error'],
                      colors: ['#00b8f0', '#ff0000'],
                      legend: {
                        fontSize: '16rem',
                        offsetY: 70,
                      },
                      chart: {
                        width: '50px',
                        height: '50px'
                      },
                      title: {
                        align: 'center',
                        margin: 30,
                        style: {
                          fontSize: '2rem'
                        },
                        text: `Resolução de Problemas / Nível - ${textOfOption}`
                      }
                    }}
                  />
                  <Chart
                    className='graphic-content'
                    type='bar'
                    width={400}
                    height={400}
                    options={{
                      chart: {
                        width: 800,
                        type: 'bar',
                        toolbar: {
                          show: true,
                        },
                      },
                      stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'butt',
                        colors: ['#000000'],
                        width: 1,
                        dashArray: 0
                      },
                      colors: ['#00b8f0', '#ff0000'],
                      dataLabels: {
                        enabled: true,
                        formatter(val) {
                          return `${val} alunos(a)`;
                        },
                        offsetY: 10,
                        style: {
                          fontSize: '12px',
                          colors: ['#304758']
                        }
                      },
                      legend: {
                        show: false
                      },
                      plotOptions: {
                        bar: {
                          columnWidth: '45%',
                          distributed: true,
                          dataLabels: {
                            position: 'top'
                          }
                        }
                      },
                      xaxis: {
                        categories: ['Acertou', 'Error'],
                        labels: {
                          style: {
                            colors: '#000000',
                            fontWeight: '600',
                            fontSize: '10px'
                          }
                        }
                      }
                    }}
                    series={[
                      {
                        data: optionSelected
                      }
                    ]}
                  />
                  <div className='problem-solving-dashboard-info'>
                    <div>
                      <h1>Resolução de Problemas</h1>
                    </div>
                    <ul>
                      <li>
                        <div style={{
                          width: 20,
                          height: 20,
                          borderRadius: '100%',
                          backgroundColor: '#00b8f0',
                        }}></div>
                        <h1>Acertou: {optionSelected[0]}</h1>
                      </li>
                      <li>
                        <div style={{
                          width: 20,
                          height: 20,
                          borderRadius: '100%',
                          backgroundColor: '#ff0000',
                        }}></div>
                        <h1>Errou: {optionSelected[1]}</h1>
                      </li>
                    </ul>
                  </div>
                </>
              )
              : (
                <img className='dashboard-default-image' src={defaultBgImg} alt="dashboard default image" />
              )
          }
        </div>
      </div>
    </>
  );
}
