
import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import type RoomsProtocol from '../types/Room/Rooms-protocol';
import type { RoomType, RoomsNames } from '../types/Room/Rooms-protocol';
import type { StudentType } from '../types/Student/student-factory-protocol';
import type { UserProtocol } from '../types/User/User-factory-protocol';
import type StudentMonetaryResType from '../types/student-monetary-aid';

class Rooms implements RoomsProtocol {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

	async post(room: RoomType): Promise<RoomType[]> {
		let newRooms: RoomType[] = [];
		await axios.post(`${this.href}/post/rooms`, room).then(response => {
			newRooms = response.data as RoomType[];
		});

		return newRooms;
	}

	async get(): Promise<RoomType[]> {
		let newRooms: RoomType[] = [];
		await axios.get(`${this.href}/get/rooms`).then(response => {
			newRooms = response.data as RoomType[];
		});

		return newRooms;
	}

  async getAllRoomInfo(): Promise<RoomType[]> {
    let newRooms: RoomType[] = [];
    await axios.get(`${this.href}/get/rooms/info`).then(response => {
      newRooms = response.data as RoomType[];
    });

    return newRooms;
  }

	async getAllRoomsByTeacher(teacherId: string): Promise<RoomType[]> {
		let newRooms: RoomType[] = [];
		await axios.get(`${this.href}/get/rooms/by/teacherId/${teacherId}`).then(response => {
			newRooms = response.data as RoomType[];
		});

		return newRooms;
	}

  async getRoomName(year: string): Promise<RoomsNames[]> {
    let roomNames: RoomsNames[] = []

    await axios.get(`${this.href}/get/rooms/name/${year}`).then(response => {
      roomNames = response.data as RoomsNames[];
    }).catch(e => {

    });

    return roomNames;
  }

	async put(rooms: any, id: string): Promise<RoomType[]> {
		let newRooms: RoomType[] = [];

		if (this.href) {
			await axios.put(`${this.href}/update/room/${id}`, rooms).then(response => {
				newRooms = response.data as RoomType[];
			});
		}

		return newRooms;
	}

	async delete(id: string): Promise<RoomType[]> {
		let newRooms: RoomType[] = [];

		await axios.delete(`${this.href}/delete/room/${id}`).then(response => {
			newRooms = response.data as RoomType[];
		});

		return newRooms;
	}

	async getAllStudentByRoom(roomId: string): Promise<StudentType[]> {
		let newStudent: StudentType[] = [];

		await axios.get(`${this.href}/get/room/students/${roomId}`).then(response => {
			newStudent = response.data as StudentType[];
		});

		return newStudent;
	}

  async getAllRoomByStudent(year: string, studentId: string): Promise<StudentMonetaryResType[]> {
    let newStudent: StudentMonetaryResType[] = [];

    await axios.get(`${this.href}/get/rooms/by/student/${studentId}/${year}`).then(response => {
      newStudent = response.data
    });

    return newStudent;
  }

	async addStudentToRoom(student: StudentType, roomId: string, studentId: string): Promise<StudentType[]> {
		let newStudent: StudentType[] = [];

		await axios.post(`${this.href}/post/room/student/${roomId}/${studentId}`, student).then(response => {
			newStudent = response.data as StudentType[];
		});

		return newStudent;
	}

	async postStudentToRoom(students: Array<StudentType | undefined>, roomId: string): Promise<StudentType[]> {
		let newStudents: StudentType[] = [];

		if (!students[0]?.studentName) {
			return [];
		}

		await axios.post(`${this.href}/post/room/students/${roomId}`, students).then(response => {
			newStudents = response.data as StudentType[];
		});

		return newStudents;
	}

  async updateStudentSituation(roomId: string, studentId: string, studentSituation: any): Promise<StudentType[]> {
    let newStudents: StudentType[] = [];

    await axios.put(`${this.href}/update/student/situation/${roomId}/${studentId}`, studentSituation).then(response => {
      newStudents = response.data as StudentType[];
    });

    return newStudents;
  }

	async deleteStudentOnRoo(roomId: string, studentId: string): Promise<StudentType[]> {
		let newStudent: StudentType[] = [];

		await axios.delete(`${this.href}/delete/room/student/${roomId}/${studentId}`).then(response => {
			newStudent = response.data as StudentType[];
		});

		return newStudent;
	}
}

export default new Rooms();
