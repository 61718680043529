/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import React, { useState, useEffect, useRef } from 'react';
import uploadElementarySchoolMonthlyPlanApi from '../../../class/UploadElementarySchoolMonthlyPlan/upload-monthly-plan-api';
import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import ContentTableElementaryMonthlyPlan from '../../../components/monthly-plan';
import FormAddElementaryMonthlyPlan from '../form-add-monthly-plan';
import DecentWorkDisplayInformation from '../work-plan-display-information';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import FormDescribeDecentWork from '../form-description-monthly-plan';
import FormEditDecentWork from '../form-edit-monthly-plan';
import EmptyComponent from '../../../components/Empty-component';
import FilterPlan from '../../../class/filterPlan';
import BackToTop from '../../../components/back-to-top/back-to-top';
import lscache from 'lscache';
import UserAPI from '../../../class/User/UserAPI';
import './style.scss';
import ReadCheckingMonthlyPlan from '../../../components/read-checking-monthly-plan';
import LoadContent from '../../../components/Load-content';
import RuleVerification from '../../../components/rule-verification';
import FileDeliveryDate from '../file-delivery-data';
import { type ElementarySchoolMonthlyPlanType } from '../../../class/types/Upload_ElementarySchoolMonthlyPlan';

const elementDefault = {
  date: '',
  fileName: '',
  location: {
    fileName: '',
    url: ''
  },
  type: '',
  checked: false,
  description: {
    content: ''
  }
};

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [elementarySchoolMonthlyPlan, setElementarySchoolMonthlyPlan] = useState<ElementarySchoolMonthlyPlanType[]>([]);
  const [displayFormAddElementaryMonthlyPlan, setDisplayElementaryMonthlyPlan] = useState(false);
  const [displayDecentWorkInfo, setDisplayDecentWorkInfo] = useState(false);
  const [displayFormEditElementaryMonthlyPlan, setDisplayEditElementaryMonthlyPlan] = useState(false);
  const [displayFormDescribeElementaryMonthlyPlan, setDisplayDescribeElementaryMonthlyPlan] = useState(false);
  const [elementId, setElementId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [elementFiltered, setElementFiltered] = useState<any[]>([]);
  const mainElement = useRef<any>();
  const [displayReadCheckingMonthlyPlan, setDisplayReadCheckingMonthlyPlan] = useState(false);

  const [element, setElement] = useState<ElementarySchoolMonthlyPlanType>(elementDefault);

  const [displayViewElementarySchoolMonthlyPlan, setDisplayViewElementarySchoolMonthlyPlan] = useState(false);
  const [displayFileDeliveryMonthlyPlan, setDisplayFileDeliveryMonthlyPlan] = useState(false);

  const huddleCreateNewElement = () => {
    setDisplayElementaryMonthlyPlan(true);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const filterPlan = new FilterPlan(inputs, elementarySchoolMonthlyPlan);

    setElementFiltered(filterPlan.searchPlans());
  };

  const hundleDisplayFileDeliveryMonthlyPlan = () => {
    setDisplayFileDeliveryMonthlyPlan(!displayFileDeliveryMonthlyPlan);
  }

  useEffect(() => {
    const user = lscache.get('user') as UserClientProtocol;
    if (!user || !user.id) {
      return;
    }

    if (display === 'false' || elementarySchoolMonthlyPlan.length > 0) {
      return;
    }

    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      uploadElementarySchoolMonthlyPlanApi.get().then((response) => {
        setIsLoading(false);
        if (permission === 'professor') {
          const monthlyPlan = response.filter(value => value.issuer?.userId === user.id)
          setElementarySchoolMonthlyPlan(monthlyPlan);
          setElementFiltered(monthlyPlan);
          return;
        }

        setElementarySchoolMonthlyPlan(response);
        setElementFiltered(response);
      }).catch((e) => {
        //
      });
    }).catch(e => {
      //
    })
  }, [display]);

  useEffect(() => {
    setElementFiltered(elementarySchoolMonthlyPlan);
  }, [elementarySchoolMonthlyPlan]);

  return (
    <div className='table-container-new-elementary-school-monthly-plan'>
      <h1 className='title-table'>Ensino Fundamental - Plano Bimestral</h1>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <ReadCheckingMonthlyPlan
        displayReadChecking={displayReadCheckingMonthlyPlan}
        elementarySchoolMonthlyPlan={elementarySchoolMonthlyPlan}
        elementId={elementId}
        setDisplayReadChecking={setDisplayReadCheckingMonthlyPlan}
        setElementaryDecentWork={setElementarySchoolMonthlyPlan}
        type='elementary-school'
      />
      <FormAddElementaryMonthlyPlan
        displayFormAddMonthlyPlan={displayFormAddElementaryMonthlyPlan}
        setDisplayFormAddMonthlyPlan={setDisplayElementaryMonthlyPlan}
        setElementarySchoolMonthlyPlan={setElementarySchoolMonthlyPlan}
        elementarySchoolMonthlyPlan={elementarySchoolMonthlyPlan}
      />
      <FormEditDecentWork
        displayFormEditMonthlyPlan={displayFormEditElementaryMonthlyPlan}
        setDisplayFormEditMonthlyPlan={setDisplayEditElementaryMonthlyPlan}
        elementarySchoolMonthlyPlan={elementarySchoolMonthlyPlan}
        setElementarySchoolMonthlyPlan={setElementarySchoolMonthlyPlan}
        setDisplayEditElement={setDisplayEditElementaryMonthlyPlan}
        elementId={elementId}
      />
      <FormDescribeDecentWork
        displayFormDescribeMonthlyPlan={displayFormDescribeElementaryMonthlyPlan}
        setDisplayFormDescribeMonthlyPlan={setDisplayDescribeElementaryMonthlyPlan}
        setElementarySchoolMonthlyPlan={setElementarySchoolMonthlyPlan}
        elementarySchoolMonthlyPlan={elementarySchoolMonthlyPlan}
        elementId={elementId}
      />
      <DecentWorkDisplayInformation
        setElement={setElement}
        setElementarySchoolMonthlyPlan={setElementarySchoolMonthlyPlan}
        elementarySchoolMonthlyPlan={elementarySchoolMonthlyPlan}
        displayDecentWorkInfo={displayDecentWorkInfo}
        setDisplayDecentWorkInfo={setDisplayDecentWorkInfo}
        element={element}
      />
      <FileDeliveryDate setDisplay={setDisplayFileDeliveryMonthlyPlan} display={displayFileDeliveryMonthlyPlan} />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar plano mensal</button>
        <RuleVerification rule={['coordenador']}>
          <button onClick={hundleDisplayFileDeliveryMonthlyPlan}>Visualizar dados de entrega</button>
        </RuleVerification>
      </div>
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='professor' />
        </div>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='nome da sala' />
        </div>
        <div className='search-by-date-content'>
          <div className='search-input-content input-content input-date'>
            <input type='date' placeholder='nome da sala' />
          </div>
          <h2>A</h2>
          <div className='search-input-content input-content input-date'>
            <input type='date' placeholder='nome da sala' />
          </div>
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>Professor</li>
          <li className='header-table-ul-li'>Sala</li>
          <li className='header-table-ul-li'>Data</li>
          <li className='header-table-ul-li visualization-element'>Visualizado</li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '10rem',
              },
            }}
          />
          : (
            display === 'true'
              ? (
                !elementFiltered
                  ? <EmptyComponent>click em (Adicionar plano bimestral) para adicionar um novo plano bimestral</EmptyComponent>
                  : elementFiltered?.map((elementarySchoolDecentWork: ElementarySchoolDecentWorkType, index: number) => (
                    <ContentTableElementaryMonthlyPlan
                      index={index}
                      elementarySchoolMonthlyPlan={elementarySchoolMonthlyPlan}
                      elementarySchoolDecentWork={elementarySchoolDecentWork}
                      setDisplayReadChecking={setDisplayReadCheckingMonthlyPlan}
                      setDisplayEditElement={setDisplayEditElementaryMonthlyPlan}
                      setDisplayViewElementarySchoolDecentWork={setDisplayViewElementarySchoolMonthlyPlan}
                      setDisplayDescribeElementaryMonthlyPlan={setDisplayDescribeElementaryMonthlyPlan}
                      setElementId={setElementId}
                      setElement={setElement}
                      setDisplayDecentWorkInfo={setDisplayDecentWorkInfo}
                      setElementarySchoolDecentWork={setElementarySchoolMonthlyPlan}
                      type={'elementary-school'}
                    />
                  ))
              )
              : ''
          )}
      </main>
    </div>
  );
}
