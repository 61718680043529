import type ProgrammaticContentResProtocol from '../types/ProgrammaticContent/ProgrammaticContentApi-protocol';
import type StrategyCustumerProtocol from '../types/custumer-protocol';
import ProgrammaticContentFactory from './programmaticContent-factory';
import ProgrammaticContentApi from './programmaticContentApi';

class ProgrammaticContentCustumer implements StrategyCustumerProtocol<ProgrammaticContentResProtocol> {
	private readonly programmaticContentApi = ProgrammaticContentApi;

	constructor(
    private readonly textarea: HTMLTextAreaElement,
    private readonly date: HTMLInputElement,
		private readonly roomId: string,
		private readonly matterId: string,
		private readonly twoMonths: string,
	) {}

	async post(): Promise<any[]> {
		let programmaticContent: ProgrammaticContentResProtocol[] = [];
		const programmaticContentFactory = new ProgrammaticContentFactory(this.textarea, this.date);

		const programmatic = programmaticContentFactory.create();

		await this.programmaticContentApi.post(this.roomId, this.matterId, this.twoMonths, programmatic).then((response: ProgrammaticContentResProtocol[]) => {
			programmaticContent = response;
		});

		return programmaticContent;
  }

	async put(activityId: string): Promise<ProgrammaticContentResProtocol[]> {
		let programmaticContent: ProgrammaticContentResProtocol[] = [];

		const programmaticContentFactory = new ProgrammaticContentFactory(this.textarea, this.date);

		const programmaticContentPut = programmaticContentFactory.createPut();

		await this.programmaticContentApi.put(this.roomId, this.matterId, this.twoMonths, activityId, programmaticContentPut).then((response: any[]) => {
			programmaticContent = response as ProgrammaticContentResProtocol[];
		});

		return programmaticContent;
	}
}

export default ProgrammaticContentCustumer;
