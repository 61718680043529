/* eslint-disable multiline-ternary */
/* eslint-disable no-negated-condition */
import React, { useState } from 'react';
import { FaUserAlt, FaLock } from 'react-icons/fa';

import './style.scss';
import LoadContent from '../Load-content';

interface FormLoginElementProps {
    hundleSubmit: (e: React.FormEvent<HTMLFormElement>) => void
    isLoading: boolean
}

export default function FormRegisterElement({
    hundleSubmit,
    isLoading
}: FormLoginElementProps): React.ReactElement {
    const [displayPassword, setDisplayPassword] = useState<boolean>(false);

    return (
        <div className="form-main">
            <form
                action="POST"
                className="form-register-element"
                onSubmit={(e) => {
                    hundleSubmit(e);
                }}>
                <div className="form-register-title">
                    <h1>Registre-se</h1>
                </div>
                <div className="input-register-content">
                    <label htmlFor="user-name">Nome de usuário: </label>
                    <br />
                    <div className="input-content">
                        <FaUserAlt size={18} className="incon-user-register" />
                        <input type="text" id="user-name" placeholder="nome de usuário" />
                    </div>
                </div>
                <div className="input-register-content">
                    <label htmlFor="user-email">E-mail: </label>
                    <br />
                    <div className="input-content">
                        <FaUserAlt size={18} className="incon-user-register" />
                        <input type="text" id="user-email" placeholder="exemplo@gmail.com" />
                    </div>
                </div>
                <div className="input-register-content">
                    <label htmlFor="user-password">Senha: </label>
                    <br />
                    <div className="input-content">
                        <FaLock size={18} className="incon-user-register" />
                        <input
                            type={!displayPassword ? 'password' : 'text'}
                            id="user-password"
                            placeholder="digite a sua senha"
                        />
                    </div>
                </div>
                <div className="input-register-content">
                    <label htmlFor="user-password-two">Repita a senha: </label>
                    <br />
                    <div className="input-content">
                        <FaLock size={18} className="incon-user-register" />
                        <input
                            type={!displayPassword ? 'password' : 'text'}
                            id="user-password-two"
                            placeholder="digite a sua senha"
                        />
                    </div>
                </div>
                <div className="input-display-password">
                    <input
                        type="checkbox"
                        id="display-password"
                        placeholder="digite a sua senha"
                        onInputCapture={() => {
                            setDisplayPassword(!displayPassword);
                        }}
                    />
                    <label htmlFor="display-password">mostrar senha?</label>
                </div>
                <div className="input-register-content button-register-content">
                    {isLoading ? (
                        <LoadContent
                          isLoading={isLoading}
                          style={{
                            button: {
                              width: 30,
                            },
                            container: {
                              justifyContent: 'flex-start',
                            }
                          }}
                        />
                    ) : (
                        <button type="submit" className="button-login">
                            Registrar-se
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
}
