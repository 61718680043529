/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useState, useEffect } from 'react';
import './style.scss';
import EmptyComponent from '../../../components/Empty-component';
import { useParams } from 'react-router-dom';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import lscache from 'lscache';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import RoomsApi from '../../../class/Room/RoomsApi';
import ContentTableStudentWritingLevelRecord from '../../../components/student-writing-level-record/ContentTable-room';
import StudentWritingLRCustomer from '../../../class/student-writing-level-record/student-writing-level-record-cutumer';
import studentWritingLevelRecordApi from '../../../class/student-writing-level-record/student-writing-level-record-api';
import type { StudentWritingLRRes, TagTitleList, TagType } from '../../../class/types/Student-writing-level-record/student-writing-level-record-factory-protocol';
import SaveAlert from '../../../components/save-alert';
import StudentWritingLevelRecordTagsManager from '../../../class/student-writing-level-record/student-writing-level-record-tags-manager';
import WritingLevelManager from '../write-level-manager';
import DashboardWritingLevel from '../Dashboard';
import UserAPI from '../../../class/User/UserAPI';
import RuleVerification from '../../../components/rule-verification';
import printWritingLevelRecord from './service/print-writing-level-record';

interface TableProps {
  activityInfo: Record<string, string>
  display: string
}

// {activityInfo.matterId} {activityInfo.twoMonths}

export default function Table({ activityInfo, display }: TableProps): React.ReactElement {
  const [user, setUser] = useState<UserClientProtocol>();
  const [rule, setRule] = useState<string>('');
  const [displaySaveAlert, setDisplaySaveAlert] = useState(false);
  const [students, setStudents] = useState<StudentType[]>([]);
  const [writingLevel, setWritingLevel] = useState<StudentWritingLRRes>([]);
  const [writingLevelTags, setWritingLevelTags] = useState<TagType[]>([]);
  const [defaultWritingLevelTag, setDefaultWritingLevelTag] = useState<TagType[]>([]);
  const [displayWritingLevelTagManager, setDisplayWritingLevelTagManager] = useState(false);
  const [displayDashboardWritingLevel, setDisplayDashboardWritingLevel] = useState(false);
  const [displayList, setDisplayList] = useState<TagTitleList[]>([
    {
      display: true,
      tag: 'tags',
      name: 'Nível de Escrita'
    },
  ]);

  const { id, roomName } = useParams();

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    const inputs = form.querySelectorAll('input');

    if (!id) {
      return;
    }

    const studentWritingLRCustomer = new StudentWritingLRCustomer(inputs, students, id, activityInfo.twoMonths);

    studentWritingLRCustomer.post().then(response => {
      setWritingLevel(response);
      huddleDisplaySaveAlert();
    });
  };

  const huddleDisplayDashboardWritingLevel = () => {
    setDisplayDashboardWritingLevel(!displayDashboardWritingLevel);
  };

  const huddlePrintWritingLevel = () => {
    if (!roomName) {
      return;
    }
    printWritingLevelRecord(students, writingLevel, displayList, roomName, activityInfo.twoMonthsName);
  }

  const huddleDisplayWritingLevelManager = () => {
    setDisplayWritingLevelTagManager(!displayWritingLevelTagManager);
  };

  const huddleDisplaySaveAlert = () => {
    if (displaySaveAlert) {
      return;
    }

    setDisplaySaveAlert(true);

    setTimeout(() => {
      setDisplaySaveAlert(false);
    }, 3000);
  };

  useEffect(() => {
    setDisplayDashboardWritingLevel(false);
    setDisplayWritingLevelTagManager(false);
    if (display === 'false' || !activityInfo || !id) {
      return;
    }

    const user = lscache.get('user');
    if (user) {
      setUser(user);
      UserAPI.getPermission(user.id).then(rule => {
        if (rule) {
          setRule(rule);
        }
      });
    }

    RoomsApi.getAllStudentByRoom(id).then(response => {
      setStudents(response);
      studentWritingLevelRecordApi.get(id, activityInfo.twoMonths).then(response => {
        setWritingLevel(response);
      });
    });

    const studentWritingLevelRecordTagsManager = new StudentWritingLevelRecordTagsManager();

    studentWritingLevelRecordTagsManager.tags(id).then(response => {
      setWritingLevelTags(response);
      setDefaultWritingLevelTag(response);
    });
  }, [display, activityInfo]);

  return (
    <form className='table-container-writing-level-record' onSubmit={e => {
      hundleSubmit(e);
    }}>
      <WritingLevelManager
        setWritingLevelTags={setWritingLevelTags}
        activityInfo={activityInfo}
        writingLevelTags={writingLevelTags}
        setDefaultWritingLevelTag={setDefaultWritingLevelTag}
        defaultWritingLevelTag={defaultWritingLevelTag}
        displayWritingLevelTagManager={displayWritingLevelTagManager}
        displayList={displayList}
        setDisplayList={setDisplayList}
        huddleDisplayWritingLevelManager={huddleDisplayWritingLevelManager}
      />
      <DashboardWritingLevel
        displayDashboardWritingLevel={displayDashboardWritingLevel}
        setDisplayDashboardWritingLevel={setDisplayDashboardWritingLevel}
        displayList={displayList}
        roomInfo={activityInfo}
      />
      <SaveAlert
        display={displaySaveAlert}
        setDisplay={setDisplaySaveAlert}
      >
        Registro níveis de escrita salvo
      </SaveAlert>
      <div className='mid-content-writing-level-record'>
        <div className='mid-element-writing-level-record'>
          <div className='header-writing-level-record'>
            <h1>Registro Níveis de Escrita - {activityInfo.twoMonthsName}</h1>
          </div>
          <div className='btn-create-new-matter-container'>
            <RuleVerification rule={['coordenador']}>
              <button type='button' onClick={huddleDisplayWritingLevelManager} className='tag-manage-button'>Gerenciar tags</button>
            </RuleVerification>
            <button type='button' onClick={huddleDisplayDashboardWritingLevel}>Gráfico</button>
            <button type='button' onClick={huddlePrintWritingLevel} className='tag-generate-pdf-button'>Imprimir</button>
          </div>
        </div>
        <div className='mid-element-writing-level-record'>
          <div className='btn-create-new-matter-container'>
            <RuleVerification rule={['coordenador']}>
              <button type='submit'>Salvar</button>
            </RuleVerification>
          </div>
        </div>
      </div>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'>Aluno</li>
          <li className='header-table-ul-li'>Escreve o nome</li>
          {displayList.map(value => {
            return <li className='header-table-ul-li' key={value.name.replaceAll(' ', '')}>{value.name}</li>
          })}
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table'>
        {display === 'true'
          ? (
            students?.length === 0
              ? <EmptyComponent></EmptyComponent>
              : students?.map((student: any, index: number) => (
                <ContentTableStudentWritingLevelRecord
                  index={index}
                  student={student}
                  activityInfo={activityInfo}
                  writingLevel={writingLevel}
                  displayList={displayList}
                  key={`writing-level-record-${index}`}
                  writingLevelTags={writingLevelTags}
                />
              ))
          )
          : ''}
      </main>
    </form>
  );
}
