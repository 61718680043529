/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useState, useEffect } from 'react';
import FormAddElement from '../FormAddElement-programmatic-content';
import './style.scss';
import EmptyComponent from '../../../components/Empty-component';
import programmaticContentApi from '../../../class/ProgrammaticContent/programmaticContentApi';
import { useParams } from 'react-router-dom';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import lscache from 'lscache';
import type ProgrammaticContentResProtocol from '../../../class/types/ProgrammaticContent/ProgrammaticContentApi-protocol';
import FormEditElement from '../FormEditElement-programmatic-content';
import ProgrammaticContentInformation from '../programmatic-content-information';
import ContentTableProgrammaticContent from '../../../components/programmatic-content-files/ContentTable-programmatic-content';
import ReadChecking from '../../../components/read-checking-programmatic-content';

interface TableProps {
  activityInfo: Record<string, string>
  display: string
}

// {activityInfo.matterId} {activityInfo.twoMonths}

// setProgrammaticContent}

export default function Table({ activityInfo, display }: TableProps): React.ReactElement {
  const [displayCreateNewElement, setDisplayCreateNewElement] = useState<boolean>(false);
  const [displayEditElement, setDisplayEditElement] = useState<boolean>(false);
  const [ProgrammaticContentResProtocol, setProgrammaticContent] = useState<ProgrammaticContentResProtocol[]>([]);
  const [elementId, setElementId] = useState<string>('');
  const [displayReadChecking, setDisplayReadChecking] = useState(false);
  const { id } = useParams();
  const [user, setUser] = useState<UserClientProtocol>();

  const [displayProgrammaticContentInformation, setDisplayProgrammaticContentInformation] = useState(false);

  const hundleCreateNewElement = () => {
    setDisplayCreateNewElement(!displayCreateNewElement);
  };

  useEffect(() => {
    if (display === 'false' || !activityInfo || !id) {
      return;
    }

    programmaticContentApi.get(id, activityInfo.matterId, activityInfo.twoMonths).then(response => {
      setProgrammaticContent(response);
    });

    const user = lscache.get('user');
    if (user) {
      setUser(user);
    }
  }, [activityInfo, display]);

  return (
		<div className='table-container-programmatic-content'>
      <ReadChecking
        displayReadChecking={displayReadChecking}
        elementId={elementId}
        setDisplayReadChecking={setDisplayReadChecking}
        setElementaryDecentWork={setProgrammaticContent}
        activityInfo={activityInfo}
      />
			<FormAddElement
				activityInfo={activityInfo}
				displayCreateNewElement={displayCreateNewElement}
				setDisplayCreateNewElement={setDisplayCreateNewElement}
				setProgrammaticContent={setProgrammaticContent}
			/>
			<FormEditElement
				displayEditElement={displayEditElement}
				activityInfo={activityInfo}
				programmaticContent={ProgrammaticContentResProtocol}
				elementId={elementId}
				setDisplayEditElement={setDisplayEditElement}
				setProgrammaticContent={setProgrammaticContent}
			/>
      <ProgrammaticContentInformation
        displayProgrammaticContentInformation={displayProgrammaticContentInformation}
        setDisplayProgrammaticContentInformation={setDisplayProgrammaticContentInformation}
        elementId={elementId}
        programmaticContent={ProgrammaticContentResProtocol}
      />
			<div className='mid-content-activity'>
				<div className='mid-element-activity'>
					<div className='header-activity'>
						<h1>Materia: {activityInfo.matterName}</h1>
						<h1>{activityInfo.twoMonthsName}</h1>
					</div>
					<div className='btn-create-new-matter-container'>
						<button onClick={hundleCreateNewElement}>adicionar um novo conteúdo</button>
					</div>
				</div>
			</div>
			<div className='header-table-element'>
				<ul className='header-table-ul'>
					<li className='header-table-ul-li'>Nº</li>
					<li className='header-table-ul-li'>Date</li>
					<li className='header-table-ul-li visualization-content'>Visualização</li>
					<li></li>
					<li></li>
				</ul>
			</div>
			<main className='main-table'>
				{ProgrammaticContentResProtocol?.length === 0
				  ? <EmptyComponent>click em (adicionar um novo conteúdo) para adicionar um novo conteúdo</EmptyComponent>
				  : ProgrammaticContentResProtocol?.map((activity: any, index: number) => (
					<ContentTableProgrammaticContent
						index={index}
						activityInfo={activityInfo}
						programmaticContent={activity}
            editElementDisplay={displayEditElement}
						setElementId={setElementId}
            setDisplayReadChecking={setDisplayReadChecking}
						setEditElementDisplay={setDisplayEditElement}
						setProgrammaticContentCostumer={setProgrammaticContent}
            setDisplayProgrammaticContentInformation={setDisplayProgrammaticContentInformation}
						key={String(activity.id)}
					/>
				  ))}
			</main>
		</div>
  );
}
