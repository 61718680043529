/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import type { UserProtocol } from '../types/User/User-factory-protocol';
import type { UserClientProtocol } from '../types/User/UserAPI-protocol';

class UserApi {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
    lscache.setExpiryMilliseconds(60000);
	}

	async post(user: UserProtocol): Promise<any> {
		let userServer = {};
		await axios.post(this.href + '/user/register', user).then(response => {
			userServer = response.data as UserClientProtocol;
		});

		return userServer;
	}

	async get(email: string, password: string): Promise<any> {
		let userServer = {};
		await axios.post(this.href + '/user/login', {
			email,
			password
		}).then(response => {
			userServer = response.data as UserClientProtocol;
		});

		return userServer;
	}

	async getByPermission(permission: 'coordinator' | 'teacher' | 'nutritionist' | 'psychologist') {
		let userServer: any[] = [];

		await axios.get(this.href + `/user/get/by/permission/${permission}`).then(response => {
			userServer = response.data;
		});

		return userServer;
	}

	async put(user: any, id: string): Promise<any> {
		let newUser: any = {};

		if (this.href) {
			await axios.put(`${this.href}/user/update`, user).then(response => {
				newUser = response.data;
			});
		}

		const lscacheUser = lscache.get('user') as UserProtocol;

		lscache.set('user', {
			token: lscacheUser.token,
			...newUser
		}, config.processTemp);

		return newUser;
	}

	async getPermission(id: string) {
		let permission = '';
		const user = lscache.get('user') as UserProtocol;

		axios.defaults.headers.common.Authorization = `Bearer ${user.token ?? ''}`;

		await axios.get(this.href + `/user/get/permission/${id}`).then(response => {
			permission = response.data;
		});

		return permission;
	}

	async deletePermission(id: string, permission: 'coordinator' | 'teacher' | 'nutritionist') {
		let userServer: any[] = [];

		await axios.delete(this.href + `/user/delete/permission/${permission}/${id}`).then(response => {
			userServer = response.data;
		});

		return userServer;
	}

	async setPermission(permission: 'coordinator' | 'teacher' | 'nutritionists' | 'psychologist', id: string) {
		let userServer: any[] = [];

		await axios.get(this.href + `/user/set/permission/${permission}/${id}`).then(response => {
			userServer = response.data;
		}).catch(e => {
      userServer = [{
        error: true,
      }]
    });

		return userServer;
	}

	async getUserBy(id: string) {
		let userServer = {};

		await axios.get(this.href + `/user/get/${id}`).then(response => {
			userServer = response.data;
		});

		return userServer;
	}

  async emailAlreadyExists(email: string) {
    let res = false;

    await axios.post(this.href + '/user/emailAlreadyExists', {
      email,
    }).then(response => {
      res = response.data.res;
    });

    return res;
  }
}

export default new UserApi();
