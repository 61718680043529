import React, { useEffect, useState } from 'react';
import './style.scss';
import FormAddTextualGenre from '../form-add-textual-genre';
import TextualGenreApi from '../../../../class/new-writing-hypothesi/textual-genre/textual-genre-api';
import { useParams } from 'react-router-dom';
import { type TextualGenreType } from '../../../../class/types/new-writing-hypothesi/textual-genre';
import { TextualGenreTableContent } from '../../../../components/new-writing-hypothesis/textual-genre';
import FormEditTextualGenre from '../form-edit-textual-genre';
import LoadContent from '../../../../components/Load-content';
import TextualGenreTagTable from '../add-tags/table';
import EmptyComponent from '../../../../components/Empty-component';

interface AddTextualGenreTableProps {
  roomInfo: any
  display: string
}

export default function AddTextualGenreTable({ roomInfo, display }: AddTextualGenreTableProps) {
  const [displayFormAddTextualGenre, setDisplayFormAddTextualGenre] = useState(false);
  const [displayFormEditTextualGenre, setDisplayFormEditTextualGenre] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displayTextualGenresTagTable, setDisplayTextualGenresTagTable] = useState(false);

  const [textualGenres, setTextualGenres] = useState<TextualGenreType[]>([]);

  const [textualGenreSelected, setTextualGenreSelected] = useState<TextualGenreType | null>(null);

  const { id } = useParams();

  const displayFormAddTextualGenreToggle = () => {
    setDisplayFormAddTextualGenre(true);
  }

  const displayEdit = () => {
    setDisplayFormEditTextualGenre(true);
  }

  const huddleDisplayTextualGenreTagTable = () => {
    setDisplayTextualGenresTagTable(!displayTextualGenresTagTable);
  }

  useEffect(() => {
    if (!id || display === 'false') {
      return;
    }

    const textualGenreApi = new TextualGenreApi();

    setIsLoading(true);
    textualGenreApi.getAll(id, roomInfo.twoMonths).then(response => {
      setIsLoading(false);
      setTextualGenres(response);
    }).catch(e => {
      //
    });

    setDisplayTextualGenresTagTable(false);
  }, [display, roomInfo])

  return (
    <div className='writing-hypothesis-table-add-textual-genre'>
      <FormAddTextualGenre
        display={displayFormAddTextualGenre}
        setDisplay={setDisplayFormAddTextualGenre}
        roomInfo={roomInfo}
        setTextualGenres={setTextualGenres}
      />
      <FormEditTextualGenre
        display={displayFormEditTextualGenre}
        setDisplay={setDisplayFormEditTextualGenre}
        roomInfo={roomInfo}
        setTextualGenres={setTextualGenres}
        textualGenreSelected={textualGenreSelected}
      />
      <TextualGenreTagTable
        display={displayTextualGenresTagTable}
        setDisplay={huddleDisplayTextualGenreTagTable}
        roomInfo={roomInfo}
        elementSelected={textualGenreSelected}
      />
      <h1 className='title-add-textual-genre-table'>Gêneros Textuais e Avaliações- {roomInfo.twoMonthsName}</h1>
      <div className='btn-add-textual-genre-table'>
        <button onClick={displayFormAddTextualGenreToggle}>Adicionar gênero textual ou avaliação</button>
      </div>
      <div className='add-textual-genre-table-content'>
        <div className='add-textual-genre-table-title'>
          <ul>
            <li>Nº</li>
            <li>Titulo</li>
            <li>Data</li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div className='add-textual-genre-table-main'>
          {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  container: {
                    marginTop: '10rem',
                  }
                }}
              />
            )
            : (
              textualGenres.length === 0
                ? <EmptyComponent>click em (Adicionar gênero textual ou avaliação) para adicionar um novo gênero textual</EmptyComponent>
                : textualGenres.map((value, index) => {
                  return <TextualGenreTableContent
                    huddleEdit={displayEdit}
                    index={index}
                    textualGenre={value}
                    key={value.id}
                    setTextualGenre={setTextualGenres}
                    roomInfo={roomInfo}
                    setTextualGenreSelected={setTextualGenreSelected}
                    huddleDisplayTextualGenreTagTitle={huddleDisplayTextualGenreTagTable}
                  />
                })
            )}
        </div>
      </div>
    </div>
  )
}
