/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useParams } from 'react-router-dom';
import { type TextualGenreType } from '../../../../class/types/new-writing-hypothesi/textual-genre';
import TextualGenreApi from '../../../../class/new-writing-hypothesi/textual-genre/textual-genre-api';
import InputSelect from '../../../../components/input-select';
import Chart from 'react-apexcharts';

interface GraphProps {
  display: string
}

const twoMonthsNames = [
  {
    twoMonthsName: '1° Bimestre',
    twoMonths: 'twoMonths-one'
  },
  {
    twoMonthsName: '2° Bimestre',
    twoMonths: 'twoMonths-two'
  },
  {
    twoMonthsName: '3° Bimestre',
    twoMonths: 'twoMonths-tree'
  },
  {
    twoMonthsName: '4° Bimestre',
    twoMonths: 'twoMonths-for'
  }
]

export default function GraphNewWritingHypothesisGeneral({ display }: GraphProps): React.ReactElement {
  const [textualGenresByTwoMonths, setTextualGenresByTwoMonths] = useState<Array<{
    twoMonth: string
    textualGenre: TextualGenreType[]
  }> | null>(null);
  const [textualGenresByTwoMonthsSelected, setTextualGenresByTwoMonthsSelected] = useState<{
    twoMonth: string
    textualGenre: TextualGenreType[]
  } | null>(null);
  const [textualGenresByTwoMonthsSelectedTwo, setTextualGenresByTwoMonthsSelectedTwo] = useState<{
    twoMonth: string
    textualGenre: TextualGenreType[]
  } | null>(null);
  const [textualGenreSelected, setTextualGenreSelected] = useState<TextualGenreType | null>();
  const [textualGenreSelectedTwo, setTextualGenreSelectedTwo] = useState<TextualGenreType | null>();
  const [labels, setLabels] = useState<string[]>(['*']);
  const [series, setSeries] = useState<number[]>([100]);
  const [colors, setColors] = useState<string[]>(['#fff']);
  const [labelsTwo, setLabelsTwo] = useState<string[]>(['*']);
  const [seriesTwo, setSeriesTwo] = useState<number[]>([100]);
  const [colorsTwo, setColorsTwo] = useState<string[]>(['#fff']);

  const [smSeries, setSmSeries] = useState<number[]>([]);
  const [sbSeries, setSbSeries] = useState<number[]>([]);

  const [displayChart, setDisplayChart] = useState(false);

  const [deleteOption, setDeleteOption] = useState<boolean>(false);
  const [deleteOptionTwo, setDeleteOptionTwo] = useState<boolean>(false);
  const [deleteOptionThree, setDeleteOptionThree] = useState<boolean>(false);
  const [deleteOptionFour, setDeleteOptionFour] = useState<boolean>(false);

  const { id } = useParams();

  const cb = (option: string, graph: 1 | 2) => {
    if (graph === 2) {
      cbTwo(option);
      return;
    }

    if (!textualGenresByTwoMonthsSelected?.textualGenre) {
      return;
    }
    const textualGenre = textualGenresByTwoMonthsSelected?.textualGenre.find(g => g.genre.includes(option));

    if (!textualGenresByTwoMonthsSelected?.textualGenre) {
      setDisplayChart(false);
      return;
    }

    if (!textualGenre?.['writing-hypothesi-result']) {
      setDisplayChart(false);
      return;
    }

    setTextualGenreSelected(textualGenre);

    const graphInfo: {
      colors: string[]
      labels: string[]
      series: number[]

    } = {
      colors: [],
      labels: [],
      series: [],

    }
    textualGenre['writing-hypothesi-result'].tags.map(t => {
      graphInfo.colors.push(t.color);
      graphInfo.labels.push(t.tag);
      graphInfo.series.push(t.count ?? 0);

      return t;
    });

    setDisplayChart(true);

    setLabels(graphInfo.labels);
    setColors(graphInfo.colors);
    setSeries(graphInfo.series);

    setLabelsTwo(['*']);
    setSeriesTwo([100]);
    setColorsTwo(['#fff']);

    setSmSeries([]);

    setDeleteOptionThree(true);
    setDeleteOptionFour(true);

    setTimeout(() => {
      setDeleteOptionThree(false);
      setDeleteOptionFour(false);
    }, 200);
  }

  const cbTwo = (option: string) => {
    if (!textualGenresByTwoMonthsSelectedTwo) {
      return;
    }
    const textualGenre = textualGenresByTwoMonthsSelectedTwo?.textualGenre.find(g => g.genre.includes(option));

    if (!textualGenresByTwoMonthsSelectedTwo?.textualGenre) {
      setDisplayChart(false);
      return;
    }

    if (!textualGenre?.['writing-hypothesi-result']) {
      setDisplayChart(false);
      return;
    }

    setTextualGenreSelectedTwo(textualGenre);

    const graphInfo: {
      colors: string[]
      labels: string[]
      series: number[]

    } = {
      colors: [],
      labels: [],
      series: [],

    }
    textualGenre['writing-hypothesi-result'].tags.map(t => {
      graphInfo.colors.push(t.color);
      graphInfo.labels.push(t.tag);
      graphInfo.series.push(t.count ?? 0);

      return t;
    });

    setDisplayChart(true);

    setLabelsTwo(graphInfo.labels);
    setColorsTwo(graphInfo.colors);
    setSeriesTwo(graphInfo.series);

    subSeries(graphInfo.series);
    sumSeries(graphInfo.series);
  }

  const cbTwoMonths = (option: string, graph: 1 | 2) => {
    const twoMonths = textualGenresByTwoMonths?.find(t => t.twoMonth.includes(option));

    if (!twoMonths) {
      return;
    }

    switch (graph) {
      case 1: {
        setTextualGenresByTwoMonthsSelected(twoMonths);

        setLabels(['*']);
        setSeries([0]);
        setColors(['#fff']);
        setLabelsTwo(['*']);
        setSeriesTwo([100]);
        setColorsTwo(['#fff']);

        setSmSeries([]);

        setDeleteOptionTwo(true);
        setDeleteOptionThree(true);
        setDeleteOptionFour(true);

        setTimeout(() => {
          setDeleteOptionTwo(false);
          setDeleteOptionThree(false);
          setDeleteOptionFour(false);
        }, 200);
        break;
      }
      case 2: {
        setTextualGenresByTwoMonthsSelectedTwo(twoMonths);

        setLabelsTwo(['*']);
        setSeriesTwo([100]);
        setColorsTwo(['#fff']);
        setDeleteOptionFour(true);

        setTimeout(() => {
          setDeleteOptionFour(false);
        }, 200);
        break;
      }
      default:
        break;
    }
  }

  const getTextualGenreByTwoMonth = async () => {
    if (!id) {
      return;
    }
    const textualGenreApi = new TextualGenreApi();

    const textualGenreTwoMonth: Array<{
      twoMonth: string
      textualGenre: TextualGenreType[]
    }> = []

    for await (const twoMonth of twoMonthsNames) {
      await textualGenreApi.getAll(id, twoMonth.twoMonths).then(response => {
        textualGenreTwoMonth.push({
          twoMonth: twoMonth.twoMonthsName,
          textualGenre: response,
        })
      }).catch(e => {
        //
      });
    }

    setTextualGenresByTwoMonths(textualGenreTwoMonth);
  }

  const subSeries = (seriesTwo: number[]) => {
    const subSeries = series.map((value, index) => {
      return seriesTwo[index] - value;
    });
    setSbSeries(subSeries);
  }
  const sumSeries = (seriesTwo: number[]) => {
    const smSeries = series.map((value, index) => {
      return seriesTwo[index] - value;
    });
    setSmSeries(smSeries);
  }

  useEffect(() => {
    if (display === 'false') {
      return;
    }

    getTextualGenreByTwoMonth().then(response => {

    }).catch(e => {

    });

    setDeleteOption(true);
    setLabels(['']);
    setSeries([100]);
    setColors(['#fff']);

    setTimeout(() => {
      setDeleteOption(false);
    }, 200);

    setDisplayChart(false);
  }, [display]);
  return (
    <div className='new-writing-hypothesi-graph'>
      <div className='new-writing-hypothesi-graph-header'>
        <h1>Gráfico de Avaliação Geral</h1>
      </div>
      <div className='new-writing-hypothesi-graph-middle'>
        <div className='new-writing-hypothesi-graph-inputs-select'>
          <h1>Gráfico 1</h1>
          <InputSelect
            options={twoMonthsNames.map(twoMonths => twoMonths.twoMonthsName)}
            title=''
            defaultValue='Bimestre'
            cb={(option) => {
              cbTwoMonths(option, 1)
            }}
            deleteAll={deleteOption}
          />
          <InputSelect
            options={textualGenresByTwoMonthsSelected?.textualGenre.map(t => t.genre) ?? []}
            title=''
            defaultValue='Avaliação'
            cb={(option) => {
              cb(option, 1);
            }}
            deleteAll={deleteOptionTwo}
          />
        </div>
        <div className='new-writing-hypothesi-graph-inputs-select'>
          <h1>Gráfico 2</h1>
          <InputSelect
            options={twoMonthsNames.map(twoMonths => twoMonths.twoMonthsName)}
            title=''
            defaultValue='Bimestre'
            cb={(option) => {
              cbTwoMonths(option, 2)
            }}
            deleteAll={deleteOptionThree}
          />
          <InputSelect
            options={textualGenresByTwoMonthsSelectedTwo?.textualGenre.map(t => t.genre) ?? []}
            title=''
            defaultValue='Gênero Textual'
            cb={(option) => {
              cb(option, 2);
            }}
            deleteAll={deleteOptionFour}
          />
        </div>
      </div>
      <div className='new-writing-hypothesi-graph-content'>
        {!displayChart
          ? (
            <img src={require('./img/graph-img.png')} alt="" />
          )
          : (
            <>
              <Chart
                className={`graphic-content display-${String(displayChart)}`}
                type='pie'
                width={window.innerWidth < 550 ? 400 : 750}
                series={series}
                options={{
                  labels,
                  colors,
                  legend: {
                    fontSize: `${window.innerWidth < 550 ? '8rem' : '16rem'}`,
                    offsetY: 70
                  },
                  stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: ['#000000'],
                    width: 0.5,
                    dashArray: 0
                  },
                  chart: {
                    width: '50px',
                    height: '50px'
                  },
                  title: {
                    align: 'center',
                    margin: 30,
                    style: {
                      fontSize: `${window.innerWidth < 550 ? '1rem' : '2rem'}`,
                    },
                    text: `Avaliação (${textualGenreSelected?.genre}) - ${textualGenresByTwoMonthsSelected?.twoMonth}`
                  }
                }}
              />
              {textualGenreSelectedTwo
                ? (
                  <Chart
                    className={`graphic-content display-${String(displayChart)}`}
                    type='pie'
                    width={window.innerWidth < 550 ? 400 : 750}
                    series={seriesTwo}
                    options={{
                      labels: labelsTwo,
                      colors: colorsTwo,
                      legend: {
                        fontSize: `${window.innerWidth < 550 ? '8rem' : '16rem'}`,
                        offsetY: 70
                      },
                      stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'butt',
                        colors: ['#000000'],
                        width: 0.5,
                        dashArray: 0
                      },
                      chart: {
                        width: '50px',
                        height: '50px'
                      },
                      title: {
                        align: 'center',
                        margin: 30,
                        style: {
                          fontSize: `${window.innerWidth < 550 ? '1rem' : '2rem'}`,
                        },
                        text: `Avaliação (${textualGenreSelectedTwo?.genre}) - ${textualGenresByTwoMonthsSelectedTwo?.twoMonth}`
                      }
                    }}
                  />
                )
                : ''}
            </>
          )}
      </div>
      {(sbSeries.length !== 0 && colorsTwo[0] !== '#fff' && displayChart)
        ? <>
          <h2>Diferença de Aluno do Gráfico 1 para o Gráfico 2</h2>
          <Chart
            className='graphic-content-bar'
            type='bar'
            width={700}
            options={{
              chart: {
                width: 800,
                type: 'bar'
              },
              stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: ['#000000'],
                width: 1,
                dashArray: 0
              },
              colors,
              dataLabels: {
                enabled: true,
                formatter(val) {
                  return `${val} Aluno`;
                },
                offsetY: 10,
                style: {
                  fontSize: '12px',
                  colors: ['#304758']
                }
              },
              legend: {
                show: false
              },
              plotOptions: {
                bar: {
                  columnWidth: '45%',
                  distributed: true,
                  dataLabels: {
                    position: 'top' // Top, center, bottom
                  }
                }
              },
              xaxis: {
                categories: labelsTwo,
                labels: {
                  style: {
                    colors: '#000000',
                    fontWeight: '600',
                    fontSize: '10px'
                  }
                }
              }
            }}
            series={[
              {
                data: sbSeries
              }
            ]}
          />
        </>
        : ''}
    </div>
  )
}
