/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */

import React, { useState, useEffect, useRef } from 'react';

import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import uploadWorkshopWeeklyPlanApi from '../../../class/UploadWorkshopWeeklytWork/upload-weekly-plan-api';
import ContentTableElementaryWeeklyPlan from '../../../components/weekly-plan';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';

import FormDescribeWeeklyPlan from '../form-description-workshop-weekly-plan';
import FormAddWeeklyPlan from '../form-add-workshop-weekly-plan';
import FormEditWeeklyPlan from '../form-edit-workshop-weekly-plan';

import EmptyComponent from '../../../components/Empty-component';
import FilterPlan from '../../../class/filterPlan';
import lscache from 'lscache';
import UserAPI from '../../../class/User/UserAPI';
import './style.scss';
import WeeklyPlanDisplayInformation from '../workshop-work-plan-display-information';
import BackToTop from '../../../components/back-to-top/back-to-top';
import ReadCheckingWeeklyPlan from '../../../components/read-checking-weekly-plan';
import LoadContent from '../../../components/Load-content';
import FileDeliveryDate from '../file-delivery-data';
import RuleVerification from '../../../components/rule-verification';

const elementDefault = {
  date: '',
  fileName: '',
  location: {
    fileName: '',
    url: ''
  },
  type: '',
  checked: false,
  description: {
    content: ''
  }
};

interface TableProps {
  display: string
}

export default function Table({ display }: TableProps): React.ReactElement {
  const [displayFormDescribeKindergartenWeeklyPlan, setDisplayDescribeKindergartenWeeklyPlan] = useState(false);
  const [displayFormAddKindergartenWeeklyPlan, setDisplayKindergartenWeeklyPlan] = useState(false);
  const [displayFormEditElementaryWeeklyPlan, setDisplayEditElementaryWeeklyPlan] = useState(false);
  const [displayDecentWorkInfo, setDisplayDecentWorkInfo] = useState(false);
  const [displayReadChecking, setDisplayReadChecking] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [displayFileDelivery, setDisplayFileDelivery] = useState(false);

  const [kindergartenSchoolWeeklyPlan, setKindergartenSchoolWeeklyPlan] = useState<ElementarySchoolDecentWorkType[]>([]);

  const [elementFiltered, setElementFiltered] = useState<any[]>([]);
  const [elementId, setElementId] = useState<string>('');

  const [element, setElement] = useState<ElementarySchoolDecentWorkType>(elementDefault);
  const mainElement = useRef<any>();

  const huddleCreateNewElement = () => {
    setDisplayKindergartenWeeklyPlan(true);
  };

  const huddleDisplayFileDelivery = () => {
    setDisplayFileDelivery(!displayFileDelivery);
  }

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const filterPlan = new FilterPlan(inputs, kindergartenSchoolWeeklyPlan);

    setElementFiltered(filterPlan.searchPlans());
  };

  useEffect(() => {
    if (display === 'false' || kindergartenSchoolWeeklyPlan.length > 0) {
      return;
    }

    const user = lscache.get('user') as UserClientProtocol;
    if (!user || !user.id) {
      return;
    }

    if (display === 'false') {
      return;
    }

    setIsLoading(true);
    UserAPI.getPermission(user.id).then(permission => {
      uploadWorkshopWeeklyPlanApi.get().then((response) => {
        setIsLoading(false);
        if (permission === 'professor') {
          const decentWork = response.filter(value => value.issuer?.userId === user.id)
          setKindergartenSchoolWeeklyPlan(decentWork);
          setElementFiltered(decentWork);
          return;
        }

        setKindergartenSchoolWeeklyPlan(response);
        setElementFiltered(response);
      }).catch((e) => {
        //
      });
    }).catch(e => {
      //
    })
  }, [display]);

  useEffect(() => {
    setElementFiltered(kindergartenSchoolWeeklyPlan);
  }, [kindergartenSchoolWeeklyPlan]);

  return (
    <div className='table-container-workshop-weekly-plan'>
      <h1 className='title-table'>Oficina - Rotina Quinzenal</h1>
      <BackToTop element={(mainElement.current as HTMLDivElement)} />
      <ReadCheckingWeeklyPlan
        displayReadChecking={displayReadChecking}
        elementarySchoolWeeklyPlan={kindergartenSchoolWeeklyPlan}
        elementId={elementId}
        setDisplayReadChecking={setDisplayReadChecking}
        setElementaryDecentWork={setKindergartenSchoolWeeklyPlan}
        type='workshop'
      />
      <FormAddWeeklyPlan
        displayFormAddWeeklyPlan={displayFormAddKindergartenWeeklyPlan}
        setDisplayFormAddWeeklyPlan={setDisplayKindergartenWeeklyPlan}
        setWorkshopWeeklyPlan={setKindergartenSchoolWeeklyPlan}
        workshopWeeklyPlan={kindergartenSchoolWeeklyPlan}
      />
      <FormEditWeeklyPlan
        displayFormEditMonthlyWeeklyPlan={displayFormEditElementaryWeeklyPlan}
        workshopWeeklyPlan={kindergartenSchoolWeeklyPlan}
        setDisplayFormEditWeeklyPlan={setDisplayEditElementaryWeeklyPlan}
        setWorkshopWeeklyPlan={setKindergartenSchoolWeeklyPlan}
        setDisplayEditElement={setDisplayEditElementaryWeeklyPlan}
        elementId={elementId}
      />
      <FormDescribeWeeklyPlan
        displayFormDescribeWeeklyPlan={displayFormDescribeKindergartenWeeklyPlan}
        elementarySchoolWeeklyPlan={kindergartenSchoolWeeklyPlan}
        setDisplayFormDescribeWeeklyPlan={setDisplayDescribeKindergartenWeeklyPlan}
        setElementarySchoolWeeklyPlan={setKindergartenSchoolWeeklyPlan}
        elementId={elementId}
      />
      <WeeklyPlanDisplayInformation
        displayWeeklyPlanInfo={displayDecentWorkInfo}
        setDisplayWeeklyPlanInfo={setDisplayDecentWorkInfo}
        setWeeklyPlanSchoolWeeklyPlan={setKindergartenSchoolWeeklyPlan}
        weeklyPlanSchoolWeeklyPlan={kindergartenSchoolWeeklyPlan}
        setElement={setElement}
        element={element}
      />
        <FileDeliveryDate
          setDisplay={setDisplayFileDelivery}
          display={displayFileDelivery}
        />
      <div className='btn-create-new-coordinator-container'>
        <button onClick={huddleCreateNewElement}>Adicionar Rotina Quinzenal</button>
        <RuleVerification rule={['coordenador']}>
          <button onClick={huddleDisplayFileDelivery}>Visualizar dados de entrega</button>
        </RuleVerification>
      </div>
      <form method='POST' action='' className='search-filter-content' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='professor' />
        </div>
        <div className='search-input-content input-content'>
          <input type='text' placeholder='nome da sala' />
        </div>
        <div className='date-container'>
          <div className='search-input-content input-content input-data'>
            <input type='date' placeholder='nome da sala' />
          </div>
          <h2>A</h2>
          <div className='search-input-content input-content input-data'>
            <input type='date' placeholder='nome da sala' />
          </div>
        </div>
        <div className='submit-form-button-content'>
          <button type='submit'>Buscar</button>
        </div>
      </form>
      <div className='header-table-element'>
        <ul className='header-table-ul'>
          <li className='header-table-ul-li'>Nº</li>
          <li className='header-table-ul-li'></li>
          <li className='header-table-ul-li'>Professor</li>
          <li className='header-table-ul-li'>Sala</li>
          <li className='header-table-ul-li'>Data</li>
          <li className='header-table-ul-li visualization-element'>Visualizado</li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <main className='main-table' ref={mainElement}>
        {isLoading
          ? <LoadContent
            isLoading={isLoading}
            style={{
              container: {
                marginTop: '8rem'
              }
            }}
          />
          : (
            display === 'true'
              ? (
                elementFiltered?.length === 0
                  ? <EmptyComponent>click em (Adicionar Rotina Quinzenal) para adicionar um novo Rotina Quinzenal</EmptyComponent>
                  : elementFiltered?.map((elementarySchoolDecentWork: ElementarySchoolDecentWorkType, index: number) => (
                    <ContentTableElementaryWeeklyPlan
                      index={index}
                      elementarySchoolWeeklyPlan={elementarySchoolDecentWork}
                      elementarySchoolWeeklyPlans={kindergartenSchoolWeeklyPlan}
                      setDisplayEditElement={setDisplayEditElementaryWeeklyPlan}
                      setDisplayReadChecking={setDisplayReadChecking}
                      setDisplayDescribeElementarySchoolWeeklyPlan={setDisplayDescribeKindergartenWeeklyPlan}
                      setElementId={setElementId}
                      setElement={setElement}
                      setDisplayWeeklyPlan={setDisplayDecentWorkInfo}
                      setElementarySchoolWeeklyPlan={setKindergartenSchoolWeeklyPlan}
                      type={'workshop'}
                    />
                  ))
              )
              : ''
          )
        }
      </main>
    </div>
  );
}
