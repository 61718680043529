/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState, useRef } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import StudentWritingLevelRecordTagsManager from '../../../class/student-writing-level-record/student-writing-level-record-tags-manager';
import type { TagTitleList, TagType } from '../../../class/types/Student-writing-level-record/student-writing-level-record-factory-protocol';
import ContentTableTag from '../../../components/student-writing-level-record/ContentTable-tags';
import StudentWritingLRApi from '../../../class/student-writing-level-record/student-writing-level-record-api'
import './style.scss';
import LoadContent from '../../../components/Load-content';
import { BsPlusCircleFill } from 'react-icons/bs';
import { HiCheck } from 'react-icons/hi';
import { MdOutlineClose } from 'react-icons/md';

interface WritingLevelManagerProps {
  setDisplayList: React.Dispatch<React.SetStateAction<TagTitleList[]>>
  setWritingLevelTags: React.Dispatch<React.SetStateAction<TagType[]>>
  setDefaultWritingLevelTag: React.Dispatch<React.SetStateAction<TagType[]>>
  huddleDisplayWritingLevelManager: () => void
  displayList: TagTitleList[]
  activityInfo: any
  writingLevelTags: TagType[]
  defaultWritingLevelTag: TagType[]
  displayWritingLevelTagManager: boolean
}

interface TagTableSelectElementProps {
  displayList: TagTitleList[]
  setDisplayList: React.Dispatch<React.SetStateAction<TagTitleList[]>>
  children: string
  activityInfo: any
  reset: () => void
  index: number
}

const defaultTagTitleList = [
  {
    display: true,
    tag: 'tags',
    name: 'Nível de Escrita'
  },
]

function TagTableSelectElement({ children, index, displayList, setDisplayList, activityInfo }: TagTableSelectElementProps): React.ReactElement {
  const [displayDeleteButton, setDisplayDeleteButton] = useState(false);
  const { id } = useParams();

  const huddleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.preventDefault();
    const target = e.target as HTMLElement;

    if (target.id === 'delete-button') {
      return;
    }

    if (displayList[index].display && index !== 0) {
      setDisplayDeleteButton(!displayDeleteButton);
    }

    setDisplayList(displayList.map((value, _index) => {
      if (_index === index) {
        value.display = true;
        return value
      }
      value.display = false;
      return value;
    }));
  }

  const huddleDelete = () => {
    if (!id || typeof displayList[index].id === 'undefined') {
      return;
    }

    StudentWritingLRApi.deleteTagTitleList(id, displayList[index].id ?? '', activityInfo.twoMonths).then(response => {
      const list = [...defaultTagTitleList];
      response.forEach(element => {
        list.push({ display: false, tag: 'tagOfTitle', ...element });
      });
      setDisplayDeleteButton(false)
      setTimeout(() => {
        setDisplayList(list);
      }, 400);
    })
  }
  return (
    <li onMouseDown={huddleClick} id="tag-title-table-select" className={`tag-title-table-select display-${displayList[index].display}`}>
      <h1>{children}</h1>
      <div className={`delete-tag-title-table-select-button display-${displayDeleteButton}`}>
        <button type="button">Cancelar</button>
        <button type="button" onClick={huddleDelete} id="delete-button">Apagar</button>
      </div>
    </li>
  );
}

export default function WritingLevelManager({
  setWritingLevelTags,
  writingLevelTags,
  displayWritingLevelTagManager,
  defaultWritingLevelTag,
  activityInfo,
  displayList,
  setDisplayList,
  setDefaultWritingLevelTag,
  huddleDisplayWritingLevelManager
}: WritingLevelManagerProps
): React.ReactElement {
  const [color, setColor] = useState('#000000');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tags, setTags] = useState<TagType[]>([]);
  const [displayAddTagToTable, setDisplayAddTagToTable] = useState(false);
  const [element, setElement] = useState<TagTitleList>({
    name: 'undefined'
  });
  const navTagTitle = useRef<null | HTMLUListElement>(null);

  const { id } = useParams();

  const handleColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    setColor(target.value);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let element: any = {};

    displayList.find((value, index) => {
      if (value.display) {
        element = {
          index,
          ...value,
        }
      }

      return false
    });

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    if (!id || !element) {
      return;
    }

    const studentWritingLevelRecordTagsManager = new StudentWritingLevelRecordTagsManager(inputs);

    setIsLoading(true);

    if (element.index === 0) {
      studentWritingLevelRecordTagsManager.postTag(id).then(response => {
        if (response.error) {
          setIsLoading(false);
          return;
        }

        displayList[element.index].tags = response;
        setDisplayList(displayList);

        displayTags();

        setWritingLevelTags(response);
        inputs[0].value = '';
        setIsLoading(false);
      });
    }

    studentWritingLevelRecordTagsManager.postTagToList(id, element.id, activityInfo.twoMonths).then(response => {
      if (!Array.isArray(response)) {
        return;
      }

      displayList[element.index].tags = response;
      setIsLoading(false);
      setDisplayList(displayList);

      displayTags();
    });
  };

  const huddleSubmitFormTitleOfTable = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!id) {
      return;
    }

    const inputs = (e.target as HTMLFormElement).querySelectorAll('input');
    const tagTitleOfTableCustumer = new StudentWritingLevelRecordTagsManager(inputs);

    tagTitleOfTableCustumer.postTagTitleList(id, activityInfo.twoMonths).then(response => {
      if (!Array.isArray(response)) {
        return;
      }
      const list = [...defaultTagTitleList];
      response.forEach(element => {
        list.push({ display: false, tag: 'tagOfTitle', ...element });
      });
      setDisplayAddTagToTable(false);
      setTimeout(() => {
        setDisplayList(list);
        hundleScrollNavTitle()
        inputs[0].value = ''
      }, 400);
    });
  }

  const reset = () => {
    displayList.map((value, index) => {
      if (index === 0) {
        return {display: true}
      }
      return { display: false }
    });
    setDisplayList(displayList);
  }

  useEffect(() => {
    if (!displayWritingLevelTagManager || !id) {
      return;
    }
    StudentWritingLRApi.getAllTagTitleList(id, activityInfo.twoMonths).then(response => {
      const list = [...defaultTagTitleList];
      response.forEach(element => {
        list.push({ display: false, tag: 'tagOfTitle', ...element });
      });
      list[0].display = true;
      setDisplayList(list);
    })
  }, [displayWritingLevelTagManager, activityInfo]);

  const hundleDisplayTags = (response: TagType[]) => {
    let element: any = {};
    displayList.find((value, index) => {
      if (value.display) {
        element = {
          index,
          ...value,
        }
      }

      return false
    });

    displayList[element.index].tags = response;

    setDisplayList(displayList);

    displayTags();
  }

  const displayTags = () => {
    displayList.forEach((value, index) => {
      if (index === 0 && value.display) {
        if (!value.tags) {
          setTags(writingLevelTags);
          setElement(value);
          return;
        }
        setTags(value.tags);
        setElement(value);
        return;
      }
      if (value.display) {
        if (!value.tags) {
          setTags([]);
          setElement({
            name: 'undefined'
          });
          return;
        }
        setTags(value.tags);
        setElement(value);
      }
    });
  }

  useEffect(() => {
    displayTags();
  }, [displayList, activityInfo]);

  const hundleScrollNavTitle = () => {
    setTimeout(() => {
      if (!navTagTitle.current) {
        return;
      }

      navTagTitle.current.scroll({
        behavior: 'smooth',
        left: 10000,
      })
    }, 200);
  }

  useEffect(() => {
    hundleScrollNavTitle()
  }, [displayAddTagToTable]);

  return (
    <div className={`writing-level-manager-container-student display-${displayWritingLevelTagManager}`}>
      <div className='form-add-element' id='form-add-element-student'>
        <div className='header-form-add-element-content'>
          <h1>
            Gerenciar tags do registro níveis de escrita
          </h1>
          <IoCloseOutline size='25' onClick={huddleDisplayWritingLevelManager} />
        </div>
        <nav className='nav-content-write-level-manager'>
          <ul ref={navTagTitle}>
            {displayList.map((list, index) => {
              return <TagTableSelectElement activityInfo={activityInfo} reset={reset} index={index} displayList={displayList} setDisplayList={setDisplayList} key={`list-tag-of-title-${index}`}>{list.name}</TagTableSelectElement>
            })}
            <li className='li-form-content tag-title-table-select'>
              <form action="POST" className={`add-new-table-select display-${displayAddTagToTable}`} onSubmitCapture={huddleSubmitFormTitleOfTable}>
                <div className='input-content-add-new-table-select'>
                  <input type="text" />
                </div>
                {displayList.length === 7 ? '' : (
                  <div className='add-Button'>
                    <button type='button' onClick={() => {
                      setDisplayAddTagToTable(!displayAddTagToTable);
                    }}>
                      <BsPlusCircleFill color="#13975a" size="25" />
                    </button>
                  </div>
                )}
                <div className='submit-end-cancel-Button'>
                  <button type='button' onClick={() => {
                    setDisplayAddTagToTable(!displayAddTagToTable);
                  }}>
                    <MdOutlineClose color="#dd3d0c" size="25" />
                  </button>
                  <button type='submit'>
                    <HiCheck color="#13975a" size="25" />
                  </button>
                </div>
              </form>
            </li>
          </ul>
        </nav>
        <form className='form-content' method='POST' action='/' onSubmitCapture={huddleSubmit}>
          <div className='input-content'>
            <input className='input-text' type='text' placeholder='nome da tag' />
            <div className='input-color-content'>
              <label
                htmlFor='input-color'
                className='label-input-color'
                style={{
                  backgroundColor: color
                }}
              ></label>
              <input className='input-color' id='input-color' type='color' onChange={handleColor} />
            </div>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <button type='submit'>Adicionar</button>
            )}
          </div>
        </form>
        <div className='header-table-content'>
          <ul className='header-table-content-ul'>
            <li>
              <h1>Nº</h1>
            </li>
            <li>
              <h1>Tag</h1>
            </li>
            <li>
              <h1>Cor</h1>
            </li>
            <li></li>
          </ul>
        </div>
        <div className='table-of-tags'>
          <div className='table'>
            {tags.map((tag, index) => (
              <ContentTableTag
                index={index}
                element={element}
                activityInfo={activityInfo}
                key={`tag-manger-${index}`}
                tag={tag}
                huddleDisplayList={hundleDisplayTags}
                setWritingLevelTags={setWritingLevelTags}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
