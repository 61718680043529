/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { IoCloseOutline } from 'react-icons/io5';
import { type RoomType } from '../../../class/types/Room/Rooms-protocol';
import { IoIosArrowDown } from 'react-icons/io';
import MatterAPI from '../../../class/Matter/MatterAPI';
import { type MatterResProtocol } from '../../../class/types/Matter/matterApi-protocol';
import StudentExamsCostumer from '../../../class/Student-Exams/student-exams-costumer';
import { type StudentExamsType } from '../../../class/types/student-exams/student-exams-protocol';
import LoadContent from '../../../components/Load-content';

interface StudentExamsFormAddProps {
  display: boolean
  rooms: RoomType[]
  elementSelected: StudentExamsType | undefined
  setStudentExams: React.Dispatch<React.SetStateAction<StudentExamsType[]>>
  huddleDisplay: () => void
}

export default function StudentExamsFormEdit({ display, rooms, elementSelected, huddleDisplay, setStudentExams}: StudentExamsFormAddProps): React.ReactElement {
  const [room, setRoom] = useState<RoomType[]>([]);
  const [displayOption, setDisplayOption] = useState<boolean>(false);
  const [displayOptionTwo, setDisplayOptionTwo] = useState<boolean>(false);
  const [option, setOption] = useState<RoomType>();
  const [optionMatter, setOptionMatter] = useState<MatterResProtocol>()
  const [matters, setMatters] = useState<MatterResProtocol[]>([]);

  const [displayRoomContent, setDisplayRoomContent] = useState<boolean>(false);
  const [displayMatterContent, setDisplayMatterContent] = useState<boolean>(false);
  const [displaySaveButtonContent, setDisplaySaveButtonContent] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const titleRef = useRef<null | HTMLInputElement>(null);
  const dateRef = useRef<null | HTMLInputElement>(null);

  const huddleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    const year = target.value.split('-')[0];

    filteredRoom(year);
    setDisplayRoomContent(true);
  }

  const filteredRoom = (year: string) => {
    const filtered = rooms.filter((_room) => {
      if (_room.date?.split('/')[2] === year) {
        return true
      }
      return false
    });

    setRoom(filtered);
  }

  const huddleDisplayOptions = () => {
    setDisplayOption(!displayOption);
  }
  const huddleDisplayOptionsMatter = () => {
    setDisplayOptionTwo(!displayOptionTwo);
  }

  const huddleRoomSelect = (room: RoomType) => {
    setOption(room);

    MatterAPI.get(room.id ?? '').then((response) => {
      setMatters(response);
      setDisplayMatterContent(true);
    }).catch(e => {
      //
    })
  }

  const huddleMatterSelect = (matter: MatterResProtocol) => {
    setOptionMatter(matter);
    setDisplaySaveButtonContent(true);
  }

  const huddleCancel = () => {
    setOption(undefined)
    setOptionMatter(undefined);
    setDisplayRoomContent(false);
    setDisplayMatterContent(false);
    setDisplaySaveButtonContent(false);

    huddleDisplay();
    if (!dateRef || !dateRef.current) {
      return;
    }

    dateRef.current.value = ''
  }

  const huddleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!option || !optionMatter || !titleRef.current) {
      return;
    }

    const studentExamsCostumer = new StudentExamsCostumer(titleRef.current, dateRef.current, option, optionMatter);
    setIsLoading(true);
    studentExamsCostumer.update(elementSelected?.id ?? '').then(response => {
      if (!response) {
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      setStudentExams(response);
      huddleCancel();
    }).catch(e => {
      //
    });
  }

  useEffect(() => {
    if (!dateRef.current) {
      return;
    }

    if (!elementSelected || !display) {
      return;
    }

    dateRef.current.value = elementSelected?.date ?? '';
    setOption(elementSelected?.room);
    setOptionMatter(elementSelected?.matter);
    setDisplayRoomContent(true);
    setDisplayMatterContent(true);
    setDisplaySaveButtonContent(true);

    const year = elementSelected.date.split('-')[0];

    filteredRoom(year);

    MatterAPI.get(elementSelected?.room.id ?? '').then(response => {
      setMatters(response);
    }).catch(e => {
      //
    });
  }, [display])

  return (
    <div className={`student-exams-form-edit display-${String(display)}`}>
      <div className='student-exams-form-add-header'>
        <h1>
          Editar Prova
        </h1>
        <IoCloseOutline onClickCapture={huddleDisplay} size={25} />
      </div>
      <form className='input-container' method='POST' action='/' onSubmit={huddleSubmit}>
        <div className='input-content date-input'>
          <label htmlFor="">Titulo</label>
          <input type="text" ref={titleRef} />
        </div>
        <div className='input-content date-input'>
          <label htmlFor="">Data</label>
          <input type="date" onChangeCapture={huddleOnChange} ref={dateRef} />
        </div>
        {displayRoomContent
          ? (
            <div className='input-element-select'>
              <h1>Escolha a Sala</h1>
              <div className='input-select' onClickCapture={huddleDisplayOptions}>
                <input type="text" value={option?.roomName ?? 'Escolha uma Sala'} disabled />
                <div>
                  <IoIosArrowDown className={`icon-arrow display-${String(displayOption)}`} size={20} />
                </div>
                <ul className={`option-list display-${String(displayOption)}`}>
                  {room.map(_room => {
                    return <li onClickCapture={() => { huddleRoomSelect(_room); }} key={_room.id}>{_room.roomName}</li>
                  })}
                </ul>
              </div>
            </div>
          )
          : ''}
        {displayMatterContent
          ? (
            <div className='input-element-select'>
              <h1>Escolha a materia</h1>
              <div className='input-select' onClickCapture={huddleDisplayOptionsMatter}>
                <input type="text" value={optionMatter?.matterName ?? 'Escolha uma materia'} disabled />
                <div>
                  <IoIosArrowDown className={`icon-arrow display-${String(displayOptionTwo)}`} size={20} />
                </div>
                <ul className={`option-list display-${String(displayOptionTwo)}`}>
                  {matters.map(_matter => {
                    return <li onClickCapture={() => { huddleMatterSelect(_matter); }} key={_matter.id}>{_matter.matterName}</li>
                  })}
                </ul>
              </div>
            </div>
          )
          : ''}
        {displaySaveButtonContent
          ? (
            <div className='bt-form-content'>
              <button type='button' onClick={huddleCancel} className='cancel-button'>Cancelar</button>
              {isLoading
                ? (
                  <LoadContent
                    isLoading={isLoading}
                    style={{
                      button: {
                        width: 25,
                      },
                      container: {
                        width: 25,
                        height: 25,
                      }
                    }}
                  />
                )
                : <button type='submit'>Salvar</button>}
            </div>
          )
          : ''}
      </form>
    </div>
  )
}
