import React from 'react';
import Table from '../Table';
import './style.scss';

interface EstrategyProps {
  id: string
  display: string
}

export default function IndividualCardReport({id, display}: EstrategyProps): React.ReactElement {
  return (
		<div className={'individual-card-report'} id={id}>
			<Table display={display}/>
		</div>
  );
}
