/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import axios from 'axios';
import lscache from 'lscache';
import config from '../../config/config';
import type { MatterResProtocol } from '../types/Matter/matterApi-protocol';
import type MatterApiProtocol from '../types/Matter/matterApi-protocol';
import type { UserProtocol } from '../types/User/User-factory-protocol';

class MatterApi implements MatterApiProtocol {
	private readonly user = lscache.get('user') as UserProtocol;
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
    lscache.setExpiryMilliseconds(60000);
	}

	async get(roomId: string): Promise<MatterResProtocol[]> {
		let matter: MatterResProtocol[] = [];
		if (this.href) {
			await axios.get(`${this.href}/get/all/matter/${roomId}`).then(response => {
				matter = response.data;
			});
		}

		this.saveMatter(matter);

		return matter;
	}

  async getMatterInfo(roomId: string): Promise<MatterResProtocol[]> {
    let matter: MatterResProtocol[] = [];

    if (this.href) {
      await axios.get(`${this.href}/get/all/matterInfo/${roomId}`).then(response => {
        matter = response.data;
      });
    }

    return matter;
  }

	async post(roomId: string, matters: MatterResProtocol): Promise<MatterResProtocol[]> {
		let matter: MatterResProtocol[] = [];

		if (this.href) {
			await axios.post(`${this.href}/post/matter/${roomId}`, matters).then(response => {
				matter = response.data as MatterResProtocol[];
			});
		}

		this.saveMatter(matter);

		return matter;
	}

	async delete(roomId: string, matterId: string): Promise<any> {
		let matters: MatterResProtocol[] = [];
		if (this.href) {
			await axios.delete(`${this.href}/delete/matter/${roomId}/${matterId}`).then(response => {
				matters = response.data as MatterResProtocol[];
			});
		}

		this.saveMatter(matters);

		return matters;
	}

  async put(roomId: string, matterId: string, matter: MatterResProtocol) {
    let matters: MatterResProtocol[] = [];

    await axios.put(`${this.href}/update/matter/${roomId}/${matterId}`, matter).then(response => {
      matters = response.data as MatterResProtocol[];
    });

    return matters
  }

	saveMatter(matters: MatterResProtocol[]) {
		lscache.set('matters', matters, config.processTemp);
	}
}

export default new MatterApi();
