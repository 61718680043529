import React, { useEffect, useState } from 'react'
import './style.scss';
import Chart from 'react-apexcharts';
import genEthnicityChartInfo from './services/genEthnicityChartInfo';
import LoadContent from '../../components/Load-content';
import StudentAPI from '../../class/Student/StudentAPI';
import InputSelect from '../../components/input-select';
import { type StudentType } from '../../class/types/Student/student-factory-protocol';

interface EthnicityChartProps {
  display: string
}

export default function EthnicityChartGeneral({ display }: EthnicityChartProps): React.ReactElement {
  const [ethnicityChartInfo, setEthnicityChartInfo] = useState<Record<
    'Branca'
    | 'Preta'
    | 'Parda'
    | 'Amarela'
    | 'Indígena', number> | undefined>();
  const [students, setStudents] = useState<StudentType[]>([]);
  const [sexSelected, setSexSelected] = useState<string>('Geral');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const selectSexOption = (option: string) => {
    setSexSelected(option)
    if (option === 'Geral') {
      const info = genEthnicityChartInfo(students);
      setEthnicityChartInfo(info);
      return;
    }

    const info = genEthnicityChartInfo(students, option);

    if (!info) {
      return;
    }

    setEthnicityChartInfo(info)
  }

  useEffect(() => {
    if (display === 'false' || typeof ethnicityChartInfo !== 'undefined') {
      return;
    }

    setIsLoading(true);
    StudentAPI.get().then(response => {
      const info = genEthnicityChartInfo(response);

      if (!info) {
        setIsLoading(false);
        return;
      }
      setEthnicityChartInfo(info);
      setIsLoading(false);
      setStudents(response)
    }).catch(e => {

    });
    //
  }, [display]);

  return (
    <div className='ethnicity-chart-content-general'>
      <div className='ethnicity-chart-content-header'>
        <h1>Gráfico de Etnia Geral</h1>
      </div>
      <div className='ethnicity-chart-general-content-filters'>
        <InputSelect
          options={['Masculino', 'Feminino', 'Geral']}
          title='Gerar gráfico por sexo:'
          defaultValue={'Geral'}
          cb={selectSexOption}
        />
      </div>
      <div className='ethnicity-chart-content'>
        {isLoading
          ? <LoadContent isLoading={isLoading} />
          : (
            !ethnicityChartInfo
              ? ''
              : <>
                <Chart
                  className='graphic-content'
                  type='pie'
                  width={window.innerWidth * 0.60}
                  height={window.innerHeight * 0.55}
                  series={[ethnicityChartInfo?.Branca ?? 0, ethnicityChartInfo?.Parda ?? 0, ethnicityChartInfo?.Preta ?? 0, ethnicityChartInfo?.Amarela ?? 0, ethnicityChartInfo?.Indígena ?? 0]}
                  options={{
                    labels: ['Brancos', 'Pretos', 'Pardos', 'Amarelos', 'Indígenas'],
                    colors: ['#FFF5EE', '#8B4513', '#F4A460', '#FFF8DC', '#FFDAB9'],
                    legend: {
                      fontSize: '20rem',
                      offsetY: 70
                    },
                    stroke: {
                      show: true,
                      curve: 'smooth',
                      lineCap: 'butt',
                      colors: ['#000000'],
                      width: 0.5,
                      dashArray: 0
                    },
                    chart: {
                      width: '50px',
                      height: '50px'
                    },
                    title: {
                      align: 'center',
                      margin: 50,
                      style: {
                        fontSize: '3rem',
                      },
                      text: `Gráfico de Etnias ${sexSelected ?? 'Geral'}`
                    }
                  }}
                />
                <div className='display-ethnicity-information'>
                  <ul>
                    <li><div className='ethnicity-color-span' style={{ width: '30px', height: '30px', borderRadius: '100%', backgroundColor: '#FFF5EE' }}></div>Brancos: {ethnicityChartInfo.Branca}</li>
                    <li><div className='ethnicity-color-span' style={{ width: '30px', height: '30px', borderRadius: '100%', backgroundColor: '#8B4513' }}></div>Pretos: {ethnicityChartInfo.Preta}</li>
                    <li><div className='ethnicity-color-span' style={{ width: '30px', height: '30px', borderRadius: '100%', backgroundColor: '#F4A460' }}></div>Pardos: {ethnicityChartInfo.Parda}</li>
                    <li><div className='ethnicity-color-span' style={{ width: '30px', height: '30px', borderRadius: '100%', backgroundColor: '#FFF8DC' }}></div>Amarelos: {ethnicityChartInfo.Amarela}</li>
                    <li><div className='ethnicity-color-span' style={{ width: '30px', height: '30px', borderRadius: '100%', backgroundColor: '#FFDAB9' }}></div>Indígenas: {ethnicityChartInfo.Indígena}</li>
                  </ul>
                </div>
              </>
          )}
      </div>
    </div>
  )
}
