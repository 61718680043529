/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect } from 'react';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import './style.scss';
import { type FrequencyElement } from '../../../class/types/Frequency/frequency-factory-protocol';

interface FrequencyInputElementProps {
  students: StudentType[]
  displayElement: boolean
  index: number
  element?: FrequencyElement
}

export default function FrequencyInputElementTwo({ students, element, displayElement, index }: FrequencyInputElementProps): React.ReactElement {
  const [inputValue, setInputValue] = useState<boolean>(false);
  const [student, setStudent] = useState<StudentType | undefined>();

  const huddleClick = () => {
    setInputValue(!inputValue);
  };

  useEffect(() => {
    if (!element) {
      return;
    }
    if (typeof element.present !== 'undefined') {
      setInputValue(element.present === 'true');
      return;
    }

    setInputValue(false);
  }, [displayElement]);

  useEffect(() => {
    const student = students.filter((student) => student.id === element?.studentId);
    setStudent(student[0]);
  }, []);

  return (
    <ul className='student-frequency-content-body-list'>
      <li>
        <h2>{index + 1}</h2>
      </li>
      <li>
        <h2>{student?.studentName}</h2>
      </li>
      <li>
        <div className='frequency-input'>
          <input type='text' value={`${inputValue}`} id={student?.id} />
          <div className={`input-frequency-label display-${inputValue}`} onClickCapture={huddleClick}>
            <div className='input-radio-element'></div>
            <div className='frequency-name'>
              <p>C</p>
              <p>F</p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
}
