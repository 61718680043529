/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable multiline-ternary */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import './style.scss';
import InputSelect from '../../../components/input-select';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import UserAPI from '../../../class/User/UserAPI';
import uploadMonthlyPlanApi from '../../../class/UploadElementarySchoolMonthlyPlan/upload-monthly-plan-api';
import { type ElementarySchoolWeeklyPlanType } from '../../../class/types/Upload_ElementarySchoolWeeklyPlan';
import printDeliveryData from './services/print-delivery-data';
import LoadingPopup from '../../../components/LoadingPopup';

interface FileDeliveryDateProps {
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
}

const monthsNames = [
  '',
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
];

const dayOfWeek = [
  'Segunda feira',
  'Terça feira',
  'Quarta feira',
  'Quinta feira',
  'Sexta feira',
  'Sábado',
  'Domingo'
]

export default function FileDeliveryDate({ display, setDisplay }: FileDeliveryDateProps): React.ReactElement {
  const [teachers, setTeachers] = useState<UserClientProtocol[]>();
  const [teacherData, setTeacherData] = useState<ElementarySchoolWeeklyPlanType[]>([]);

  const [months, setMonths] = useState<Array<{ month: string, days: string[] }>>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [year, setYear] = useState<number | string>(new Date().getFullYear());

  const [loadingPopUp, setLoadingPopUp] = useState<boolean>(false);

  const huddleOptionSelect = (teacherName: string) => {
    const teacher = teachers?.find(teacher => teacher.userName.includes(teacherName));

    if (!teacher) {
      return;
    }

    if (!teacher.id) {
      return;
    }

    getDateFromApi(teacher?.id)
  }

  const filterDataByDate = (date: string) => {
    if (!teacherData) {
      return [];
    }

    const filteredData = teacherData.filter(item => {
      const dateSlice = item.date.split('-');
      const formatDate = `${dateSlice[2]}/${dateSlice[1]}/${dateSlice[0]}`;

      if (formatDate === date) {
        return true;
      }
      return false;
    });

    return filteredData;
  }

  const getWeek = (date: string) => {
    const week = new Date(`${date.split('/')[1]}/${date.split('/')[0]}/${date.split('/')[2]}`)
      .getDay();

    const day = week - 1;

    return dayOfWeek[day !== -1 ? day : 6];
  }

  const getDateFromApi = (userId: string) => {
    setIsLoading(true);
    uploadMonthlyPlanApi.getData(userId).then((data) => {
      setTeacherData(data);
      setIsLoading(false);
    }).catch(() => {
      //
    });
  }

  const getDaysInMonth = (year: number, month: number) => {
    const daysInMonth = [];
    const date = new Date(year, month - 1, 1);

    while (date.getMonth() === month - 1) {
      daysInMonth.push(new Date(date).toLocaleDateString('pt-BR', { dateStyle: 'short' }));
      date.setDate(date.getDate() + 1);
    }

    return daysInMonth;
  }

  const getDayOfYear = () => {
    const month = [];

    for (let i = 1; i <= 12; i++) {
      month.push({
        month: monthsNames[i],
        days: getDaysInMonth(Number(year), i),
      });
    }

    return month;
  }

  const huddlePrint = () => {
    try {
      const userName = teacherData[0].issuer?.userName;

      if (!userName) {
        return;
      }
      setLoadingPopUp(true);
      printDeliveryData(userName, months, filterDataByDate, getWeek).then(() => {
        setLoadingPopUp(false);
      }).catch(() => {
      })
    } catch (err) {
      //
    }
  }

  const formatLongText = (text: string) => {
    if (text.length >= 50) {
      return text.slice(0, 50) + '...';
    }

    return text;
  }

  useEffect(() => {
    if (!display) {
      return;
    }

    UserAPI.getByPermission('teacher').then(response => {
      setTeachers(response);
    }).catch(e => {
      //
    });

    const months = getDayOfYear();

    setMonths(months);
  }, [display]);

  useEffect(() => {
    const months = getDayOfYear();

    setMonths(months);
  }, [year]);

  if (!display) {
    return <></>;
  }

  return (
    <div className={`elementary-school-monthly-plan-file-delivery-data display-${String(display)}`}>
      <LoadingPopup display={loadingPopUp}>O pdf esta sendo gerado...</LoadingPopup>
      <div className='kindergarten-school-file-delivery-data-title'>
        <h1>Visualizar dados de entrega Plano Mensal - Ensino fundamental</h1>
        <div className='button-container'>
          <button type='button' className='button-content' onClickCapture={huddlePrint}>
            Imprimir
          </button>
          <button className='button-content' onClick={() => {
            setDisplay(false);
          }}>
            Fechar
          </button>
        </div>
      </div>
      <div className='file-delivery-date-input-year-content'>
        <h1>Ano Letivo</h1>
        <input type="number" placeholder='Ano Letivo' onChangeCapture={(e) => {
          const target = e.target as HTMLInputElement;

          setYear(target.value);
        }} />
      </div>
      <div className='kindergarten-school-file-delivery-data-input-data'>
        <InputSelect
          options={teachers?.map(teacher => teacher.userName) ?? []}
          defaultValue='Nome do professor'
          title='Selecione o Professor'
          cb={huddleOptionSelect}
        />
        <div>
          {teacherData.length === 0 ? '' : (
            <h1>{teacherData[0].issuer?.userName ?? ''} entregou {teacherData.length} documentos</h1>
          )}
        </div>
      </div>
      <div className='kindergarten-school-file-delivery-data-table'>
        {months.map((month, index) => {
          return (
            <table id={`table-of-weekly-plan-delivery-data-${index}`}>
              <thead>
                <tr>
                  <th colSpan={5}>{month.month}</th>
                </tr>
                <tr>
                  <th>Dia</th>
                  <th>Data</th>
                  <th>Nome</th>
                  <th>Sala</th>
                  <th>Documento</th>
                </tr>
              </thead>
              <tbody>
                {month.days.map((day) => {
                  return (
                    <tr key={day}>
                      <td>{getWeek(day)}</td>
                      <td>{day}</td>
                      <td>
                        {filterDataByDate(day).map((item, index) => {
                          return (
                            <div key={item.id}>
                              <p>{index + 1} - {item.issuer?.userName}</p>
                            </div>
                          )
                        })}
                      </td>
                      <td>
                        {filterDataByDate(day).map((item) => {
                          return (
                            <div key={item.id}>
                              <p>{item.classRoom}</p>
                            </div>
                          )
                        })}
                      </td>
                      <td>
                        {filterDataByDate(day).map((item) => {
                          return (
                            <div key={item.id}>
                              <p>{formatLongText(item.fileName)}</p>
                            </div>
                          )
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )
        })}
      </div>
    </div>
  )
}
