/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import InputSelect from '../../../components/input-select';
import RoomCustumer from '../../../class/Room/Room-custumer';

interface FormAddElementProps {
  displayStudentSituationOfRoom: boolean
  setDisplayStudentSituationOfRoom: (_boolean: React.SetStateAction<boolean>) => void
  setStudentRoom: React.Dispatch<React.SetStateAction<StudentType[]>>
  studentId: string
}

export default function FormUpdateStudentSituationOfRoom({ displayStudentSituationOfRoom, setDisplayStudentSituationOfRoom, setStudentRoom, studentId }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [deleteInputSelect, setDeleteInputSelect] = useState<boolean>(false);

  const { id } = useParams();

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;

    const input = target.querySelectorAll('input');

    const roomCostumer = new RoomCustumer(input);

    if (!id || !studentId) {
      return;
    }

    setIsLoading(true);
    roomCostumer.updateStudentSituationOfRoom(id, studentId).then((response) => {
      setStudentRoom(response);
      setDeleteInputSelect(!deleteInputSelect);
      setIsLoading(false);
      setDisplayElement(!displayElement)
    });
  };

  const hundleClickClose = (clear?: boolean) => {
    setDisplayElement(false);
    setDisplayStudentSituationOfRoom(false);
    setDeleteInputSelect(!deleteInputSelect);
  };

  useEffect(() => {
    setDisplayElement(displayStudentSituationOfRoom);
  }, [displayStudentSituationOfRoom]);

  return (
    <div className={`form-add-container-room display-${displayElement}`}>
      <form method='POST' className='form-add-element-room' id='form-add-student-situation-of-room' onSubmit={e => {
        hundleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Transferir Aluno
          </h1>
          <IoCloseOutline onClick={() => {
            hundleClickClose(false);
          }} size='25' />
        </div>
        <div className="input-content">
          <InputSelect
            defaultValue='Status'
            options={[
              'Ativo', 'Transferido'
            ]}
            title='Alterar status do aluno'
            deleteAll={deleteInputSelect}
          />
        </div>
        <div className='body-form-add-element-content'>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  hundleClickClose(true);
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
