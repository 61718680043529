/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import React, { useEffect, useState } from 'react';
import './style.scss';

import { AiFillHome } from 'react-icons/ai';
import { BiFace } from 'react-icons/bi';
import { BsCalendarWeekFill, BsJournalBookmarkFill } from 'react-icons/bs';
import { FaBook, FaChartPie, FaRegFile } from 'react-icons/fa';

import { useParams } from 'react-router-dom';
import lscache from 'lscache';
import { IoIosArrowDown } from 'react-icons/io';
import { TbReportAnalytics } from 'react-icons/tb';
import type { MatterResProtocol } from '../../../class/types/Matter/matterApi-protocol';
import MatterList from '../matter-list';
import MatterAPI from '../../../class/Matter/MatterAPI';
import type { UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import RuleVerification from '../../rule-verification';
import MatterElementFinalResult from '../matter-element-final-result';

type Elements = Array<{
  name: string
  boolean: string
}>;

interface NavProps {
  elements: Elements
  setElements: (elements: Elements) => void
  setActivityInfo: React.Dispatch<React.SetStateAction<Record<string, unknown>>>
}

export default function Nav({ setElements, setActivityInfo, elements }: NavProps): React.ReactElement {
  const [matters, setMatters] = useState<MatterResProtocol[]>([]);
  const [user, setUser] = useState<UserClientProtocol>();
  const roomName = useParams().roomName

  // TwoMonths
  const [displayElement1, setDisplayElement1] = useState(false);
  const [displayElement2, setDisplayElement2] = useState(false);
  const [displayElement3, setDisplayElement3] = useState(false);
  const [displayElement4, setDisplayElement4] = useState(false);
  const [displayElement5, setDisplayElement5] = useState(false);

  // Writing level record
  const [displayElement6, setDisplayElement6] = useState(false);
  const [displayElement7, setDisplayElement7] = useState(false);
  const [displayElement8, setDisplayElement8] = useState(false);
  const [displayElement9, setDisplayElement9] = useState(false);

  // CardReport
  const [displayElement10, setDisplayElement10] = useState(false);
  const [displayElement11, setDisplayElement11] = useState(false);

  // Math level record
  const [displayElement12, setDisplayElement12] = useState(false);
  const [displayElement13, setDisplayElement13] = useState(false);
  const [displayElement14, setDisplayElement14] = useState(false);
  const [displayElement15, setDisplayElement15] = useState(false);
  const [displayElement16, setDisplayElement16] = useState(false);
  const [displayElement17, setDisplayElement17] = useState(false);
  const [displayElement18, setDisplayElement18] = useState(false);
  const [displayElement19, setDisplayElement19] = useState(false);
  const [displayElement20, setDisplayElement20] = useState(false);
  const [displayElement21, setDisplayElement21] = useState(false);
  const [displayElement22, setDisplayElement22] = useState(false);

  const [displayNav, setDisplayNav] = useState(false);

  const [displayB, setDisplayB] = useState(false);

  const { id } = useParams();

  const huddleDisplayNav = () => {
    setDisplayNav(!displayNav);
  }

  const onClickLi = (elementName: string) => {
    const elementsReset = resetElements(elements);

    elementsReset.forEach(element => {
      if (element.name === elementName) {
        element.boolean = 'true';
      }
    });
    setElements(elementsReset);
  };

  const resetElements = (elements: Elements) => {
    elements.forEach(element => {
      element.boolean = 'false';
    });
    return elements;
  };

  const redirect = (href: string) => {
    window.location.href = href;
  };

  const huddleSetMatter = async () => {
    if (!id) {
      return;
    }

    const user = lscache.get('user');

    const matters: MatterResProtocol[] = [];
    await MatterAPI.getMatterInfo(id).then(response => {
      response.forEach(matter => {
        if (matter.teacherInfo.teacherId === user?.id) {
          matters.push(matter);
        }
      });
      if (user?.permission?.rule === 'coordenador' || user?.permission?.rule === 'console') {
        setMatters(response);
        return;
      }

      setMatters(matters);
    }).catch(e => {
      //
    });
  };

  useEffect(() => {
    const user = lscache.get('user');
    if (user) {
      setUser(user);
      huddleSetMatter();
    }
  }, []);

  const huddleClickCustomer = (element: string, twoMonths: string, twoMonthsName: string) => {
    onClickLi(element);
    setActivityInfo({
      twoMonths,
      twoMonthsName
    });
  };

  return (
    <>
      <div className={`display-nav-button-content display-${displayNav}`}>
        <button onClick={huddleDisplayNav} className='button-display-nav-element'></button>
      </div>
      <nav className={`nav-component-home display-${String(displayNav)}`}>
        <div className='action-title'>
          <h1>Sala</h1>
          <p>{roomName}</p>
        </div>
        <ul className='ul-option'>
          <li className='option-content-1' id='estrategy-1' onClick={e => {
            redirect('/');
          }}>
            <AiFillHome size='20' color='#fff' />
            <h1 id='estrategy-1'>Inicio</h1>
          </li>
          <RuleVerification rule={['coordenador']}>
            <>
              <li className='option-content-1' onClick={() => {
                onClickLi('strategy-10');
              }}>
                <FaBook size='20' color='#fff' />
                <h1>Protocolo de Acompanhamento de Sala de Aula</h1>
              </li>
              <li className='option-content-1' onClick={() => {
                onClickLi('strategy-11');
              }}>
                <FaRegFile size='20' color='#fff' />
                <h1>Ficha de Observação de sala</h1>
              </li>
            </>
          </RuleVerification>
          <RuleVerification rule={['coordenador']}>
            <li className='option-content-1' id='estrategy-1' onClick={() => {
              onClickLi('strategy-2');
            }}>
              <BsJournalBookmarkFill size='20' d='estrategy-1' color='#FFF' />
              <h1 id='estrategy-1'>Matérias</h1>
            </li>
          </RuleVerification>
          <li className='option-content-1' id='estrategy-1' onClick={() => {
            onClickLi('strategy-1');
          }}>
            <BiFace size='20' d='estrategy-1' color='#FFF' />
            <h1 id='estrategy-1'>Alunos</h1>
          </li>
          <li className='option-content-1' id='estrategy-1' onClick={() => {
            setDisplayB(!displayB);
          }}>
            <BsCalendarWeekFill size='20' d='estrategy-1' color='#FFF' />
            <div className='option-title-content'>
              <h1 id='estrategy-1'>Bimestre</h1>
              <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayB}`} />
            </div>
          </li>
          <li className={`option-content-two-months display-${displayB}`}>
            <ul className='options-content-room'>
              <li className='option-content' onClick={() => {
                setDisplayElement1(!displayElement1);
              }}>
                <h1>1º Bimestre</h1>
                <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement1}`} />
              </li>
              <li className={`option-matter display-${displayElement1}`}>
                <li className='option-li' onClick={() => {
                  setDisplayElement6(!displayElement6);
                }}>
                  <h1>
                    Registro Níveis de Escrita (antigo)
                  </h1>
                  <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement6}`} />
                </li>
                <li className={`option-li-writing-level-record display-${displayElement6}`}>
                  <ul className={'option-writing-level-record'}>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-7', 'twoMonths-one', '1º Bimestre');
                    }}>
                      <h1>Hipótese de Escrita</h1>
                    </li>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-9', 'twoMonths-one', '1º Bimestre');
                    }}>
                      <h1>Nível Descrição</h1>
                    </li>
                  </ul>
                </li>
                <li className='option-li' onClick={() => {
                  setDisplayElement18(!displayElement18);
                }}>
                  <h1>
                    Avaliações Diagnostica
                  </h1>
                  <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement18}`} />
                </li>
                <li className={`option-li-writing-level-record display-${displayElement18}`}>
                  <ul className={'option-writing-level-record'}>
                    <RuleVerification rule={['coordenador']}>
                      <>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-22', 'twoMonths-one', '1º Bimestre');
                        }}>
                          <h1>Avaliações</h1>
                        </li>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-21', 'twoMonths-one', '1º Bimestre');
                        }}>
                          <h1>Adicionar gênero textual</h1>
                        </li>
                      </>
                    </RuleVerification>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-23', 'twoMonths-one', '1º Bimestre');
                    }}>
                      <h1>Gráfico</h1>
                    </li>
                    <RuleVerification rule={['coordenador']}>
                      <>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-25', 'twoMonths-one', '1° Bimestre');
                        }}>
                          <h1>Aluno Escreve o Nome</h1>
                        </li>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-9', 'twoMonths-one', '1º Bimestre');
                        }}>
                          <h1>Nível de Escrita</h1>
                        </li>
                      </>
                    </RuleVerification>
                  </ul>
                </li>
                <li className='option-li' onClick={() => {
                  setDisplayElement12(!displayElement12);
                }}>
                  <h1>
                    Hipótese de Matemática
                  </h1>
                  <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement12}`} />
                </li>
                <li className={`option-li-writing-level-record display-${displayElement12}`} >
                  <ul className={'option-writing-level-record'}>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-17', 'twoMonths-one', '1º Bimestre');
                    }}>
                      <h1>Sondagem de Matemática</h1>
                    </li>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-19', 'twoMonths-one', '1º Bimestre');
                    }}>
                      <h1>Resolução de Problemas</h1>
                    </li>
                  </ul>
                </li>
                <MatterList
                  onClickLi={onClickLi}
                  twoMonths='twoMonths-one'
                  twoMonthsName='1º Bimestre'
                  matters={matters}
                  setActivityInfo={setActivityInfo}
                />
              </li>
              <li className='option-content' onClick={() => {
                setDisplayElement2(!displayElement2);
              }}>
                <h1>2º Bimestre</h1>
                <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement2}`} />
              </li>
              <li className={`option-matter display-${displayElement2}`}>
                <li className='option-li' onClick={() => {
                  setDisplayElement7(!displayElement7);
                }}>
                  <h1>
                    Registro Níveis de Escrita (Antigo)
                  </h1>
                  <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement7}`} />
                </li>
                <li className={`option-li-writing-level-record display-${displayElement7}`}>
                  <ul className={'option-writing-level-record'}>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-7', 'twoMonths-two', '2º Bimestre');
                    }}>
                      <h1>Hipótese de Escrita</h1>
                    </li>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-9', 'twoMonths-two', '2º Bimestre');
                    }}>
                      <h1>Nível Descrição</h1>
                    </li>
                  </ul>
                </li>
                <li className='option-li' onClick={() => {
                  setDisplayElement19(!displayElement19);
                }}>
                  <h1>
                    Avaliações Diagnostica
                  </h1>
                  <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement18}`} />
                </li>
                <li className={`option-li-writing-level-record display-${displayElement19}`}>
                  <ul className={'option-writing-level-record'}>
                    <RuleVerification rule={['coordenador']}>
                      <>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-22', 'twoMonths-two', '2º Bimestre')
                        }}>
                          <h1>Avaliações</h1>
                        </li>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-21', 'twoMonths-two', '2º Bimestre')
                        }}>
                          <h1>Adicionar Tags</h1>
                        </li>
                      </>
                    </RuleVerification>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-23', 'twoMonths-two', '2º Bimestre')
                    }}>
                      <h1>Gráfico</h1>
                    </li>
                    <RuleVerification rule={['coordenador']}>
                      <>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-25', 'twoMonths-two', '2° Bimestre');
                        }}>
                          <h1>Aluno Escreve o Nome</h1>
                        </li>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-9', 'twoMonths-two', '2º Bimestre');
                        }}>
                          <h1>Nível de Escrita</h1>
                        </li>
                      </>
                    </RuleVerification>
                  </ul>
                </li>
                <li className='option-li' onClick={() => {
                  setDisplayElement14(!displayElement14);
                }}>
                  <h1>
                    Hipótese de Matemática
                  </h1>
                  <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement12}`} />
                </li>
                <li className={`option-li-writing-level-record display-${displayElement14}`} >
                  <ul className={'option-writing-level-record'}>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-17', 'twoMonths-two', '2º Bimestre');
                    }}>
                      <h1>Sondagem de Matemática</h1>
                    </li>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-19', 'twoMonths-two', '2º Bimestre');
                    }}>
                      <h1>Resolução de Problemas</h1>
                    </li>
                  </ul>
                </li>
                <MatterList
                  onClickLi={onClickLi}
                  twoMonths='twoMonths-two'
                  twoMonthsName='2º Bimestre'
                  matters={matters}
                  setActivityInfo={setActivityInfo}
                />
              </li>
              <li className='option-content' onClick={() => {
                setDisplayElement3(!displayElement3);
              }}>
                <h1>3º Bimestre</h1>
                <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement3}`} />
              </li>
              <li className={`option-matter display-${displayElement3}`}>
                <li className='option-li' onClick={() => {
                  setDisplayElement8(!displayElement8);
                }}>
                  <h1>
                    Registro Níveis de Escrita (Antigo)
                  </h1>
                  <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement8}`} />
                </li>
                <div className={`option-li-writing-level-record display-${displayElement8}`}>
                  <ul className={'option-writing-level-record'}>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-7', 'twoMonths-tree', '3º Bimestre');
                    }}>
                      <h1>Hipótese de Escrita</h1>
                    </li>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-9', 'twoMonths-tree', '3º Bimestre');
                    }}>
                      <h1>Nível Descrição</h1>
                    </li>
                  </ul>
                </div>
                <li className='option-li' onClick={() => {
                  setDisplayElement20(!displayElement20);
                }}>
                  <h1>
                    Avaliações Diagnostica
                  </h1>
                  <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement18}`} />
                </li>
                <li className={`option-li-writing-level-record display-${displayElement20}`}>
                  <ul className={'option-writing-level-record'}>
                    <RuleVerification rule={['coordenador']}>
                      <>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-22', 'twoMonths-tree', '3º Bimestre')
                        }}>
                          <h1>Avaliações</h1>
                        </li>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-21', 'twoMonths-tree', '3º Bimestre')
                        }}>
                          <h1>Adicionar Tags</h1>
                        </li>
                      </>
                    </RuleVerification>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-23', 'twoMonths-tree', '3º Bimestre')
                    }}>
                      <h1>Gráfico</h1>
                    </li>
                    <RuleVerification rule={['coordenador']}>
                      <>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-25', 'twoMonths-tree', '3° Bimestre');
                        }}>
                          <h1>Aluno Escreve o Nome</h1>
                        </li>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-9', 'twoMonths-tree', '3º Bimestre');
                        }}>
                          <h1>Nível de Escrita</h1>
                        </li>
                      </>
                    </RuleVerification>
                  </ul>
                </li>
                <li className='option-li' onClick={() => {
                  setDisplayElement15(!displayElement15);
                }}>
                  <h1>
                    Hipótese de Matemática
                  </h1>
                  <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement15}`} />
                </li>
                <li className={`option-li-writing-level-record display-${displayElement15}`} >
                  <ul className={'option-writing-level-record'}>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-17', 'twoMonths-tree', '3º Bimestre');
                    }}>
                      <h1>Sondagem de Matemática</h1>
                    </li>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-19', 'twoMonths-tree', '3º Bimestre');
                    }}>
                      <h1>Resolução de Problemas</h1>
                    </li>
                  </ul>
                </li>
                <MatterList
                  onClickLi={onClickLi}
                  twoMonths='twoMonths-tree'
                  twoMonthsName='3º Bimestre'
                  matters={matters}
                  setActivityInfo={setActivityInfo}
                />
              </li>
              <li className='option-content' onClick={() => {
                setDisplayElement4(!displayElement4);
              }}>
                <h1>4º Bimestre</h1>
                <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement4}`} />
              </li>
              <li className={`option-matter display-${displayElement4}`}>
                <li className='option-li' onClick={() => {
                  setDisplayElement9(!displayElement9);
                }}>
                  <h1>
                    Registro Níveis de Escrita (Antigo)
                  </h1>
                  <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement9}`} />
                </li>
                <li className={`option-li-writing-level-record display-${displayElement9}`}>
                  <ul className={'option-writing-level-record'}>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-7', 'twoMonths-for', '4º Bimestre');
                    }}>
                      <h1>Hipótese de Escrita</h1>
                    </li>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-9', 'twoMonths-for', '4º Bimestre');
                    }}>
                      <h1>Nível Descrição</h1>
                    </li>
                  </ul>
                </li>
                <li className='option-li' onClick={() => {
                  setDisplayElement21(!displayElement21);
                }}>
                  <h1>
                    Avaliações Diagnostica
                  </h1>
                  <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement18}`} />
                </li>
                <li className={`option-li-writing-level-record display-${displayElement21}`}>
                  <ul className={'option-writing-level-record'}>
                    <RuleVerification rule={['coordenador']}>
                      <>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-22', 'twoMonths-for', '4º Bimestre')
                        }}>
                          <h1>Avaliações</h1>
                        </li>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-21', 'twoMonths-for', '4º Bimestre')
                        }}>
                          <h1>Adicionar Tags</h1>
                        </li>
                      </>
                    </RuleVerification>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-23', 'twoMonths-for', '4º Bimestre')
                    }}>
                      <h1>Gráfico</h1>
                    </li>
                    <RuleVerification rule={['coordenador']}>
                      <>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-25', 'twoMonths-for', '4° Bimestre');
                        }}>
                          <h1>Aluno Escreve o Nome</h1>
                        </li>
                        <li onClick={() => {
                          huddleClickCustomer('strategy-9', 'twoMonths-for', '4º Bimestre');
                        }}>
                          <h1>Nível de Escrita</h1>
                        </li>
                      </>
                    </RuleVerification>
                  </ul>
                </li>
                <li className='option-li' onClick={() => {
                  setDisplayElement16(!displayElement16);
                }}>
                  <h1>
                    Hipótese de Matemática
                  </h1>
                  <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement16}`} />
                </li>
                <li className={`option-li-writing-level-record display-${displayElement16}`} >
                  <ul className={'option-writing-level-record'}>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-17', 'twoMonths-for', '4º Bimestre');
                    }}>
                      <h1>Sondagem de Matemática</h1>
                    </li>
                    <li onClick={() => {
                      huddleClickCustomer('strategy-19', 'twoMonths-for', '4º Bimestre');
                    }}>
                      <h1>Resolução de Problemas</h1>
                    </li>
                  </ul>
                </li>
                <MatterList
                  onClickLi={onClickLi}
                  twoMonths='twoMonths-for'
                  twoMonthsName='4º Bimestre'
                  matters={matters}
                  setActivityInfo={setActivityInfo}
                />
              </li>
            </ul>
          </li>
          <li className='option-content-1' id='estrategy-1' onClick={() => {
            setDisplayElement10(!displayElement10);
          }}>
            <TbReportAnalytics size='20' id='estrategy-1' color='#FFF' />
            <div className='option-title-content'>
              <h1 id='estrategy-1'>Boletim</h1>
              <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement10}`} />
            </div>
          </li>
          <li className={`option-content-two-months display-${displayElement10}`}>
            <ul className='options-content-room'>
              <li
                className='option-content-card-report' onClickCapture={() => {
                  onClickLi('strategy-15')
                }}>
                <h1>
                  ATA
                </h1>
              </li>
              <li
                className='option-content-card-report' onClickCapture={() => {
                  onClickLi('strategy-13')
                }}>
                <h1>
                  Boletim
                </h1>
              </li>
              <RuleVerification rule={['coordenador', 'professor']}>
                <li className='option-content-card-report' onClickCapture={() => {
                  setDisplayElement11(!displayElement11);
                }}>
                  <h1>
                    Conclusão Final
                  </h1>
                  <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement11}`} />
                </li>
              </RuleVerification>
              <ul className={`ul-matter-option-element display-${displayElement11}`}>
                {matters.map(matter => <MatterElementFinalResult
                  key={matter.id ?? ''}
                  setActivityInfo={setActivityInfo}
                  onClickLi={onClickLi}
                  matter={matter}
                />)}
              </ul>
            </ul>
          </li>
          <RuleVerification rule={['coordenador']}>
            <li className='option-content-1' id='estrategy-1' onClick={() => {
              setDisplayElement17(!displayElement17);
            }}>
              <FaChartPie size='20' id='estrategy-1' color='#FFF' />
              <div className='option-title-content'>
                <h1 id='estrategy-1'>Gráficos</h1>
                <IoIosArrowDown size='20' color='#fff' className={`icon-arrow display-${displayElement17}`} />
              </div>
            </li>
          </RuleVerification>
          <li className={`option-content-two-months display-${displayElement17}`}>
            <ul className='options-content-room'>
              <li
                className='option-content-card-report' onClickCapture={() => {
                  onClickLi('strategy-8')
                }}>
                <h1>
                  Hipótese de Escrita Geral (Antigo)
                </h1>
              </li>
              <li className='option-content-card-report' onClickCapture={() => {
                onClickLi('strategy-24')
              }}>
                <h1>Hipótese de Escrita Geral</h1>
              </li>
              <li
                className='option-content-card-report' onClickCapture={() => {
                  onClickLi('strategy-18')
                }}>
                <h1>
                  Diagnostico de Matemática Geral
                </h1>
              </li>
              <li
                className='option-content-card-report' onClickCapture={() => {
                  onClickLi('strategy-20')
                }}>
                <h1>
                  Resolução de Problemas Geral
                </h1>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </>
  );
}
