/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState, useRef } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import UserAPI from '../../../class/User/UserAPI';
import clearAllInputs from './service/clear-all-inputs';
import './style.scss';
import LoadContent from '../../../components/Load-content';
import displayError from '../../../class/services/displayError';

interface FormAddElementProps {
  displayCreateNewElement: boolean
  setDisplayCreateNewElement: (_boolean: React.SetStateAction<boolean>) => void
  setTeacher: React.Dispatch<React.SetStateAction<any[] | undefined>>
}

export default function FormAddElement({ displayCreateNewElement, setDisplayCreateNewElement, setTeacher }: FormAddElementProps): React.ReactElement {
  const [displayElement, setDisplayElement] = useState<boolean>(true);
  const [user, setUser] = useState<Record<string, string>>({});
  const inputRef = useRef<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hundleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    UserAPI.setPermission('nutritionists', inputRef.current.value).then((response: any) => {
      if (response.error) {
        displayError(inputRef.current, 'ID do usuário e invalido');
        setIsLoading(false);
        return;
      }

      if (response[0].error) {
        displayError(inputRef.current, 'ID do usuário e invalido');
        setIsLoading(false);
        return;
      }

      setTeacher(response);
      setDisplayElement(false);
      clearAllInputs();
      setUser({});
      setIsLoading(false);
    });
  };

  const hundleClickClose = (clear?: boolean) => {
    setDisplayElement(false);
    setDisplayCreateNewElement(false);
    setUser({});

    if (clear) {
      clearAllInputs();
    }
  };

  useEffect(() => {
    setDisplayElement(displayCreateNewElement);

    if (!displayCreateNewElement) {
      return;
    }

    UserAPI.getByPermission('nutritionist').then(response => {
      setTeacher(response);
    });
  }, [displayCreateNewElement]);

  return (
    <div className={`form-add-container-home display-${displayElement}`}>
      <form method='POST' className='form-add-element-coordinator' id='form-add-element-coordinator' onSubmit={e => {
        hundleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Adicionar um novo nutricionista
          </h1>
          <IoCloseOutline onClick={() => {
            hundleClickClose(false);
          }} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <div className='input-content'>
            <label htmlFor='user-id'>Id do usuário:</label>
            <input type='text' id='user-id' placeholder='digite o id do usuário' ref={inputRef} autoComplete='off' />
          </div>
          {user?.id
            ? <div className='user-information'>
              <h1>Informações do usuário</h1>
              <ul className='user-ul user-information-title'>
                <li>Nome</li>
                <li>Id</li>
              </ul>
              <ul className='user-ul user-information-content'>
                <li>{user.userName}</li>
                <li>{user.id}</li>
              </ul>
            </div>
            : (
              user?.error ? <span className='error-content'>{user.error}</span> : ''
            )}
          <div className='buttons-content'>
            {
              isLoading ? (
                  <LoadContent
                    isLoading={isLoading}
                    style={
                      {
                        button: {
                          fontSize: 25,
                        },
                        container: {
                          width: 25,
                        }
                      }
                    }
                  />
                ) : (
                  <>
                    <button className='cancel-button' type='button' onClick={() => {
                      hundleClickClose(true);
                    }}>Cancelar</button>
                    <button className='save-button' type='submit'>Adicionar</button>
                  </>
                )
            }
          </div>
        </div>
      </form>
    </div>
  );
}
