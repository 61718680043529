/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react';
import './style.scss';
import { IoCloseOutline } from 'react-icons/io5';
import DisplayFileInfo from '../../../components/DisplayFile';
import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import lscache from 'lscache';
import UpLoadElementaryMonthlyPlanCostumer from '../../../class/UploadElementarySchoolMonthlyPlan/upload-monthly-plan-custumer';
import LoadContent from '../../../components/Load-content';

interface FormAddMonthlyPlanProps {
  setDisplayFormAddMonthlyPlan: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolMonthlyPlan: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType[]>>
  displayFormAddMonthlyPlan: boolean
  elementarySchoolMonthlyPlan: ElementarySchoolDecentWorkType[]
}

export default function FormAddDecentWork({ setDisplayFormAddMonthlyPlan, setElementarySchoolMonthlyPlan, displayFormAddMonthlyPlan, elementarySchoolMonthlyPlan }: FormAddMonthlyPlanProps): JSX.Element {
  const [fileInfo, setFileInfo] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');

  const huddleCancel = () => {
    setDisplayFormAddMonthlyPlan(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const elementarySchoolMonthlyPlanCustomer = new UpLoadElementaryMonthlyPlanCostumer(inputs);

    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true)
    elementarySchoolMonthlyPlanCustomer.post().then(response => {
      if (!response) {
        setIsLoading(false);
        return;
      }
      setElementarySchoolMonthlyPlan([response, ...elementarySchoolMonthlyPlan]);
      setDisplayFormAddMonthlyPlan(false);
      setIsLoading(false);
    }).catch(e => {
      //
    });
  };

  const setDateHuddle = () => {
    const date = (new Date()).toLocaleDateString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
    }).split('/');

    const formattedDate = `${date[2]}-${date[1]}-${date[0]}`;

    setDate(formattedDate);
  }

  const inputFileHuddle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target.files?.item(0)) {
      return;
    }

    setFileInfo(target.files?.item(0));
  };

  useEffect(() => {
    setDateHuddle();
  }, []);

  return (
    <div className={`form-add-elementary-school-monthly-plan display-${String(displayFormAddMonthlyPlan)}`}>
      <div className='header-form-add-element-content'>
        <h1>
          Adicionar novo  plano de bimestral
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormAddMonthlyPlan(false);
        }} size='25' />
      </div>
      <form method='POST' action='/' className='main-form-add-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <div className='input-element-content'>
          <label htmlFor='class-room-content-form-add-kindergarten-school-teaching-work-plan'>Sala:</label>
          <input id='class-room-content-form-add-kindergarten-school-teaching-work-plan' type="text" />
        </div>
        <div className='input-element-content'>
          <label>Data</label>
          <input type="date" min={date} />
        </div>
        <div className="input-file-element">
          <label htmlFor="file-input-elementary-school-monthly-plan">
            <DisplayFileInfo fileInfo={fileInfo} />
          </label>
          <input
            type="file"
            id='file-input-elementary-school-monthly-plan'
            onChange={inputFileHuddle}
            accept='.docx, vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpeg, .jpg, .png'
          />
        </div>
        <div className='button-content'>
          <div>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                <button type='submit' className='save-button'>Salvar</button>

              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
