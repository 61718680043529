/* eslint-disable multiline-ternary */

/* eslint-disable react/jsx-key */

/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import FrequencyCustomer from '../../../class/Frequency/frequency-custumer';
import RoomsApi from '../../../class/Room/RoomsApi';
import type { FrequencyType } from '../../../class/types/Frequency/frequency-factory-protocol';
import type { StudentType } from '../../../class/types/Student/student-factory-protocol';
import FrequencyInputElement from '../../../components/frequency-files/frequency-input-element';
import clearAllInputs from './service/clear-all-inputs';
import './style.scss';
import LoadContent from '../../../components/Load-content';

interface FormAddElementProps {
  displayCreateNewElement: boolean
  activityInfo: Record<string, string>
  setDisplayCreateNewElement: (_boolean: React.SetStateAction<boolean>) => void
  setFrequency: React.Dispatch<React.SetStateAction<FrequencyType[]>>
}

// {activityInfo.matterId} {activityInfo.twoMonths}

export default function FormAddElement({ displayCreateNewElement, activityInfo, setDisplayCreateNewElement, setFrequency }: FormAddElementProps): React.ReactElement {
  const [students, setStudents] = useState<StudentType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams();

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');
    if (!id) {
      return;
    }

    const frequencyCustomer = new FrequencyCustomer(inputs, id, activityInfo.matterId, activityInfo.twoMonths, students);

    setIsLoading(true);
    frequencyCustomer.post().then(response => {
      setFrequency(response);
      setDisplayCreateNewElement(false);
      setDisplayCreateNewElement(false);
      clearAllInputs();
      setIsLoading(false);
    });
  };

  const huddleClickClose = (clear?: boolean) => {
    setDisplayCreateNewElement(false);
    setDisplayCreateNewElement(false);

    if (clear) {
      clearAllInputs();
    }
  };

  useEffect(() => {
    if (!displayCreateNewElement) {
      return;
    }
    if (!id) {
      return;
    }

    RoomsApi.getAllStudentByRoom(id).then(response => {
      setStudents(response);
    });
  }, [displayCreateNewElement]);

  return (
    <div className={`form-add-container-frequency display-${displayCreateNewElement}`}>
      <form method='POST' className='form-add-element-activity' id='form-add-element-frequency' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Lançamento de frequência
          </h1>
          <IoCloseOutline onClick={() => {
            huddleClickClose(false);
          }} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <div className='student-frequency-content'>
            <div className='student-frequency-content-header'>
              <ul className='student-frequency-content-header-list'>
                <li>
                  <h2>N°</h2>
                </li>
                <li>
                  <h2>Aluno</h2>
                </li>
                <li>
                  <h2>Presença</h2>
                </li>
              </ul>
            </div>
            <div className='student-frequency-content-body'>
              {!students ? '' : students.map((student, index) => <FrequencyInputElement displayElement={displayCreateNewElement} index={index} student={student} />)}
            </div>
          </div>
          <div className='input-date-content'>
            <label htmlFor='add-frequency-input-date'>Data:</label>
            <input id='add-frequency-input-date' type="date" />
          </div>
          <div className='buttons-content'>
            {isLoading ? (
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            ) : (
              <>
                <button className='cancel-button' type='button' onClick={() => {
                  huddleClickClose(true);
                }}>Cancelar</button>
                <button className='save-button' type='submit'>Salvar</button>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
