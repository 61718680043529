/* eslint-disable new-cap */
/* eslint-disable react/jsx-key */
/* eslint-disable multiline-ternary */

/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */

import { type CardReportByMatter, type FrequencyByMatter } from '../../../class/types/IndividualCardReporting';
import React, { useState, useEffect } from 'react';
import type IndividualCardReportType from '../../../class/types/IndividualCardReporting';
import FrequencyFinalResultFormat from '../service/frequency-final-result-format';
import SumJustificationOfAbsences from '../service/sum-justification-of-absences';
import individualCardReport from '../../../class/individual-card-report';
import { type StudentType } from '../../../class/types/Student/student-factory-protocol';
import FrequencyFormat from '../service/frequency-format';
import { useParams } from 'react-router-dom';
import InputSelect from '../../../components/input-select';
import LoadContent from '../../../components/Load-content';
import generatePDF from '../service/generetePDF';
import RoomsApi from '../../../class/Room/RoomsApi';
import 'jspdf-autotable';
import './style.scss';

interface TableProps {
  display: string
}

export default function Table({display}: TableProps): React.ReactElement {
  const [displayTable, setDisplayTable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [students, setStudents] = useState<StudentType[] | undefined>();
  const [student, setStudent] = useState<IndividualCardReportType>();
  const [displayGeral, setDisplayGeral] = useState(true);
  const [twoMonths, setTwoMonths] = useState('Geral');
  const { id, roomName } = useParams();

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!id) {
      return;
    }
    const target = e.target as HTMLFormElement;

    const inputs = target.querySelectorAll('input');

    const studentId = inputs.item(0).id.split('id_')[1];

    if (studentId.length === 0) {
      return;
    }

    setDisplayTable(false);
    setIsLoading(true);

    individualCardReport.getReadingFluency(id, studentId).then(response => {
      setDisplayTable(true);
      setIsLoading(false);
      setStudent(response);
    });
  };

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short',
    })
  }

  const printElement = () => {
    generatePDF(roomName ?? '', twoMonths, student?.studentName ?? '');
  }

  const huddleSetStudent = (option: any) => {
    //
  }

  const huddleSetTwoMonths = (option: any) => {
    if (option === 'Geral') {
      setDisplayGeral(true);
    } else {
      setDisplayGeral(false);
    }
    setTwoMonths(option);
  }

  useEffect(() => {
    if (display === 'false' || !id) {
      return;
    }

    RoomsApi.getAllStudentByRoom(id).then(response => {
      setStudents(response);
    });
  }, [display]);

  return (
    <div className='table-container-individual-card-report'>
      <section className='header-content'>
        <h1>Boletim - {roomName}</h1>
        <form action="POST" className='form-search-student-individual-card-report' onSubmit={huddleSubmit}>
          <InputSelect
            options={(students?.map(value => value.studentName)) ?? ['']}
            title='Nome do aluno: '
            defaultValue='Nome Do Estudante'
            cb={huddleSetStudent}
            id={(students?.map(value => value.id ?? ''))}
          />
          <InputSelect
            options={[
              '1º Bimestre',
              '2º Bimestre',
              '3º Bimestre',
              '4º Bimestre',
              'Geral'
            ]}
            title='Bimestre: '
            defaultValue='1º Bimestre'
            cb={huddleSetTwoMonths}
            id={(students?.map(value => value.id ?? ''))}
          />
          {!isLoading ? (
            <button className='search-button' type='submit'>Buscar</button>
          ) : (
            <LoadContent
              isLoading={isLoading}
              style={
                {
                  button: {
                    width: 20,
                  },
                  container: {
                    width: 50,
                  }
                }
              }
            />
          )}
          <button className='search-button' type='button' onClickCapture={printElement}>Imprimir</button>
        </form>
      </section>
      {!isLoading ? '' : (
        <LoadContent
          isLoading={isLoading}
          style={
            {
              container: {
                marginTop: 60,
                height: 100,
              }
            }
          }
        />
      )}
      {!displayTable ? '' : (
        <section className='individual-card-report-container' id='individual-card-report-table'>
          <div className='student-information-content'>
            <ul>
              <li>Aluno(a): {student?.studentName}</li>
              <li>Data de Nascimento: {formatDate(students?.filter(_student => _student.id === student?.studentId)[0]?.birthday ?? '')}</li>
              <li>RA: {students?.filter(_student => _student.id === student?.studentId)[0]?.ra?.content ?? ''}</li>
              <li>Sala: {roomName}</li>
            </ul>
          </div>
          <div className={`individual-card-report-header-container display-general-${String(displayGeral)}`}>
            <div className='individual-card-report-header-title'>
              <h1>Disciplina</h1>
            </div>
            {['Geral', '1º Bimestre'].includes(twoMonths) ? (
              <div className='individual-card-report-header-twoMonths-content'>
                <div className='individual-card-report-header-twoMonths-title'>
                  <h2>1º Bimestre</h2>
                </div>
                <div className='individual-card-report-header-twoMonths-information'>
                  <div>N</div>
                  <div>F</div>
                  <div>AC</div>
                  <div>%Freq</div>
                </div>
              </div>
            ) : (
              ''
            )}
            {['Geral', '2º Bimestre'].includes(twoMonths) ? (
              <div className='individual-card-report-header-twoMonths-content'>
                <div className='individual-card-report-header-twoMonths-title'>
                  <h2>2º Bimestre</h2>
                </div>
                <div className='individual-card-report-header-twoMonths-information'>
                  <div>N</div>
                  <div>F</div>
                  <div>AC</div>
                  <div>%Freq</div>
                </div>
              </div>
            ) : (
              ''
            )}
            {
              ['Geral', '3º Bimestre'].includes(twoMonths) ? (
                <div className='individual-card-report-header-twoMonths-content'>
                  <div className='individual-card-report-header-twoMonths-title'>
                    <h2>3º Bimestre</h2>
                  </div>
                  <div className='individual-card-report-header-twoMonths-information'>
                    <div>N</div>
                    <div>F</div>
                    <div>AC</div>
                    <div>%Freq</div>
                  </div>
                </div>
              ) : (
                ''
              )
            }
            {
              ['Geral', '4º Bimestre'].includes(twoMonths) ? (
                <div className='individual-card-report-header-twoMonths-content'>
                  <div className='individual-card-report-header-twoMonths-title'>
                    <h2>4º Bimestre</h2>
                  </div>
                  <div className='individual-card-report-header-twoMonths-information'>
                    <div>N</div>
                    <div>F</div>
                    <div>AC</div>
                    <div>%Freq</div>
                  </div>
                </div>
              ) : (
                ''
              )
            }
            {
              twoMonths === 'Geral' ? (
                <div className='individual-card-report-header-twoMonths-content'>
                  <div className='individual-card-report-header-twoMonths-title'>
                    <h2>CF</h2>
                  </div>
                  <div className='individual-card-report-header-twoMonths-information'>
                    <div>N</div>
                    <div>F</div>
                    <div>AC</div>
                    <div>%Freq</div>
                  </div>
                </div>
              ) : (
                ''
              )
            }
          </div>
          <div className='individual-card-report-table-container'>
            {!student ? '' : student.frequency.map((freq, index) => {
              return <IndividualCardReportRow
                twoMonths={twoMonths}
                freq={freq}
                displayGeneral={displayGeral}
                card={student.cardReport[index]}
                finalResult={student.finalResult[index].cardReport ?? {}}
              />
            })}
          </div>
        </section>
      )}
    </div>
  );
}

interface IndividualCardReportRowProps {
  freq: FrequencyByMatter
  card: CardReportByMatter
  displayGeneral: boolean
  twoMonths: string
  finalResult: any
}

function IndividualCardReportRow({ freq, card, finalResult, displayGeneral, twoMonths }: IndividualCardReportRowProps): React.ReactElement {
  const verifyValue = (_value: any) => {
    let value = _value;

    if (!value) {
      value = '-'
    }
    if (value === 0) {
      value = '-';
    }
    if (value === '0') {
      value = '-';
    }
    return value;
  }
  return (
    <div className={`individual-card-report-table-row display-general-${String(displayGeneral)}`}>
      <div className='matter-content'>{freq.matterName}</div>
      {['Geral', '1º Bimestre'].includes(twoMonths) ? (
        <div className='matter-information'>
          <div>{verifyValue(card.cardReport['twoMonths-one'].grade)}</div>
          <div>{verifyValue(freq.frequency['twoMonths-one'].missed)}</div>
          <div>{verifyValue(freq.frequency['twoMonths-one'].justificationOfAbsences)}</div>
          <div>{FrequencyFormat(freq.frequency['twoMonths-one'])}</div>
        </div>
      ) : (
        ''
      )}
      {['Geral', '2º Bimestre'].includes(twoMonths) ? (
        <div className='matter-information'>
          <div>{verifyValue(card.cardReport['twoMonths-two'].grade)}</div>
          <div>{verifyValue(freq.frequency['twoMonths-two'].missed)}</div>
          <div>{verifyValue(freq.frequency['twoMonths-two'].justificationOfAbsences)}</div>
          <div>{FrequencyFormat(freq.frequency['twoMonths-two'])}</div>
        </div>
      ) : (
        ''
      )}
      {['Geral', '3º Bimestre'].includes(twoMonths) ? (
        <div className='matter-information'>
          <div>{verifyValue(card.cardReport['twoMonths-tree'].grade)}</div>
          <div>{verifyValue(freq.frequency['twoMonths-tree'].missed)}</div>
          <div>{verifyValue(freq.frequency['twoMonths-tree'].justificationOfAbsences)}</div>
          <div>{FrequencyFormat(freq.frequency['twoMonths-tree'])}</div>
        </div>
      ) : (
        ''
      )}
      {
        ['Geral', '4º Bimestre'].includes(twoMonths) ? (
          <div className='matter-information'>
            <div>{verifyValue(card.cardReport['twoMonths-for'].grade)}</div>
            <div>{verifyValue(freq.frequency['twoMonths-for'].missed)}</div>
            <div>{verifyValue(freq.frequency['twoMonths-for'].justificationOfAbsences)}</div>
            <div>{FrequencyFormat(freq.frequency['twoMonths-for'])}</div>
          </div>
        ) : (
          ''
        )
      }
      {twoMonths === 'Geral' ? (
        <div className='matter-information'>
          <div>{!finalResult ? '-' : finalResult.cardReport?.average ?? '-'}</div>
          <div>{!finalResult ? '-' : finalResult.frequencyData?.absents ?? '-'}</div>
          <div>{SumJustificationOfAbsences(freq.frequency)}</div>
          <div>{FrequencyFinalResultFormat(
            Number(finalResult.frequencyData?.present),
            Number(finalResult.frequencyData?.absents),
            freq.frequency,
          )}</div>
        </div>
      ) : ''}
    </div>
  )
};
