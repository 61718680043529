
import RoomFactory from './Room-factory';
import RoomsApi from './RoomsApi';
import type { RoomType, TeacherInfoType } from '../types/Room/Rooms-protocol';
import Validator from '../Validator';
import type { StudentType } from '../types/Student/student-factory-protocol';

export default class RoomCustumer {
	constructor(
		private readonly inputs?: NodeListOf<HTMLInputElement>,
		private readonly teacherInfo?: TeacherInfoType
	) {}

	async addNewRoom(): Promise<RoomType[]> {
		if (!this.inputs || !this.teacherInfo) {
			return [];
		}

		const roomFactory = new RoomFactory(this.inputs, this.teacherInfo);

		const validation = new Validator(this.inputs);

		if (!validation.init()) {
			const room = roomFactory.createRoom();

			const rooms = await RoomsApi.post(room);

			return rooms;
		}

		return [];
	}

	async put(id: string): Promise<RoomType[]> {
		let room: RoomType[] = [];

		if (!this.inputs || !this.teacherInfo) {
			return [];
		}

		const roomFactory = new RoomFactory(this.inputs, this.teacherInfo);

		const roomPut = roomFactory.createPut();

		await RoomsApi.put(roomPut, id).then((response: RoomType[]) => {
			room = response;
		});

		return room;
	}

	async addStudentToRoom(student: StudentType, roomId: string) {
		const studentId = student.id;

		const newStudent: StudentType = {
			district: student.district,
			ra: {
				content: student.ra?.content,
				uf: student.ra?.uf
			},
			responsible: student.responsible,
			responsibleNumber: student.responsibleNumber,
			sex: student.sex,
			situation: student.situation,
			studentName: student.studentName,
			birthday: student.birthday,
      monetaryAid: student.monetaryAid,
		};

		let studentResponse: StudentType[] = [];

		if (typeof studentId !== 'string') {
			return [];
		}

		await RoomsApi.addStudentToRoom(newStudent, roomId, studentId).then(response => {
			studentResponse = response;
		});

		return studentResponse;
	}

  async updateStudentSituationOfRoom(roomId: string, studentId: string) {
    let studentResponse: StudentType[] = [];

    if (typeof studentId !== 'string' || !this.inputs) {
      return [];
    }

    const roomFactory = new RoomFactory(this.inputs);

    const studentSituation = roomFactory.createStudentSituationOfRoom();

    await RoomsApi.updateStudentSituation(roomId, studentId, studentSituation).then(response => {
      studentResponse = response;
    });

    return studentResponse;
  }
}
