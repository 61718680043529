/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import type MathematicsProblemSolvingTagType from '../../../class/types/MathematicsProblemSolving';
import InputSelect from '../../../components/input-select';
import MathematicsProblemSolvingApi from '../../../class/Mathematics-problem-solving/MathematicsProblemSolving-api';
import Chart from 'react-apexcharts';
import { useParams } from 'react-router-dom';
import generatePDF from './service/generetePDF';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultBgImg = require('./img/undraw_Statistic_chart_re_w0pk.png');

interface DashboardProblemSolvingProps {
  display: boolean
  roomInfo: any
  tags: MathematicsProblemSolvingTagType[]
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
}

export default function DashboardProblemSolving({ display, roomInfo, tags, setDisplay }: DashboardProblemSolvingProps): React.ReactElement {
  const [dataProblemSolving, setDataProblemSolving] = useState<{
    right: number[]
    wrong: number[]
  }>({
    right: [],
    wrong: []
  });

  const [dataProblemSolvingSelected, setDataProblemSolvingSelected] = useState<number[]>([]);

  const [textOption, setTextOption] = useState<string>('')

  const { id } = useParams();

  const huddleDisplay = () => {
    setDisplay(!display);
  }

  const huddleSelect = (option: string) => {
    const indexTag = Number(option.split(' ')[0]) - 1;

    setTextOption(option.split(' ')[1]);

    setDataProblemSolvingSelected([
      dataProblemSolving.right[indexTag],
      dataProblemSolving.wrong[indexTag],
    ]);
  }

  const huddleGeneratePDF = () => {
    generatePDF(roomInfo.twoMonthsName);
  }

  useEffect(() => {
    if (!display || !id || !roomInfo.twoMonths) {
      return;
    }

    setTextOption('');
    setDataProblemSolvingSelected([]);

    const mathematicsProblemSolvingApi = new MathematicsProblemSolvingApi();

    mathematicsProblemSolvingApi.getDateByTwoMonths(id, roomInfo.twoMonths).then(response => {
      setDataProblemSolving(response);
    }).catch(() => {
      //
    })
  }, [display]);

  return (
    <div className={`dashboard-problem-solving display-${String(display)}`}>
      <div className='dashboard-problem-solving-header'>
        <h1>Resolução de Problemas - {roomInfo.twoMonthsName}</h1>
        <div className='button-content'>
          <button type="button" onClick={huddleGeneratePDF}>Imprimir</button>
          <button type="button" onClick={huddleDisplay}>Voltar</button>
        </div>
      </div>
      <div className='dashboard-problem-solving-input-select'>
        <InputSelect
          title='Selecione o Nível'
          defaultValue='Nível'
          cb={huddleSelect}
          options={tags.map((tag, index) => `${index + 1} ${tag.content}`)}
        />
      </div>
      <div className='problem-solving-dashboard-content' id='problem-solving-dashboard-content'>
        {
          dataProblemSolvingSelected.length > 0
            ? (
              <>
                <Chart
                  type='pie'
                  width={window.innerWidth < 650 ? 350 : 700}
                  height={400}
                  series={dataProblemSolvingSelected}
                  options={{
                    labels: ['Acertou', 'Error'],
                    colors: ['#00b8f0', '#ff0000'],
                    legend: {
                      fontSize: '16rem',
                      offsetY: 70,
                    },
                    chart: {
                      width: '50px',
                      height: '50px'
                    },
                    title: {
                      align: 'center',
                      margin: 30,
                      style: {
                        fontSize: window.innerWidth < 650 ? '1.5rem' : '2rem'
                      },
                      text: `Resolução de Problemas / Nível - ${textOption}`
                    }
                  }}
                />
                <Chart
                  className='graphic-content'
                  type='bar'
                  width={window.innerWidth < 650 ? 300 : 400}
                  height={400}
                  options={{
                    chart: {
                      width: 800,
                      type: 'bar',
                      toolbar: {
                        show: true,
                      },
                    },
                    stroke: {
                      show: true,
                      curve: 'smooth',
                      lineCap: 'butt',
                      colors: ['#000000'],
                      width: 1,
                      dashArray: 0
                    },
                    colors: ['#00b8f0', '#ff0000'],
                    dataLabels: {
                      enabled: true,
                      formatter(val) {
                        return `${val} alunos(a)`;
                      },
                      offsetY: 10,
                      style: {
                        fontSize: '12px',
                        colors: ['#304758']
                      }
                    },
                    legend: {
                      show: false
                    },
                    plotOptions: {
                      bar: {
                        columnWidth: '45%',
                        distributed: true,
                        dataLabels: {
                          position: 'top' // Top, center, bottom
                        }
                      }
                    },
                    xaxis: {
                      categories: ['Acertou', 'Errou'],
                      labels: {
                        style: {
                          colors: '#000000',
                          fontWeight: '650',
                          fontSize: '10px'
                        }
                      }
                    }
                  }}
                  series={[
                    {
                      data: dataProblemSolvingSelected
                    }
                  ]}
                />
                <div className='problem-solving-dashboard-info'>
                  <div>
                    <h1>Diagnóstico de Matemática</h1>
                  </div>
                  <ul>
                    <li>
                      <div style={{
                        width: 20,
                        height: 20,
                        borderRadius: '100%',
                        backgroundColor: '#00b8f0',
                      }}></div>
                      <h1>Acertou: {dataProblemSolvingSelected[0]}</h1>
                    </li>
                    <li>
                      <div style={{
                        width: 20,
                        height: 20,
                        borderRadius: '100%',
                        backgroundColor: '#ff0000',
                      }}></div>
                      <h1>Errou: {dataProblemSolvingSelected[1]}</h1>
                    </li>
                  </ul>
                </div>
              </>
            )
            : (
              <img className='dashboard-default-image' src={defaultBgImg} alt="dashboard default image" />
            )
        }
      </div>
    </div>
  );
}
